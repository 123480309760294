import React from 'react';
import { Accordion } from '@/modules/entenda-mais/components';
import { Section } from '@/modules/entenda-mais/layout';

const Coupon = (): JSX.Element => {
  const sectionDetails = {
    title: 'Cupom',
    // image: HowToPlayPicture04,
    text: [{ paragraph: '' }],
    index: 3,
  };

  return (
    <Section
      header={sectionDetails.title}
      text={sectionDetails.text}
      // image={sectionDetails.image}
      index={sectionDetails.index}
    >
      <Accordion header="Distribuição de Cupons de Bônus">
        <>
          <p>
            O Cartola Express poderá oferecer cupons de bônus aos usuários a seu
            livre e exclusivo critério, sujeitos às regras de elegibilidade
            descritas abaixo.
          </p>
        </>
      </Accordion>

      <Accordion header="Resgate de Cupons por Usuários com Depósito">
        <>
          <p>
            Alguns cupons de bônus poderão ser resgatados apenas por usuários
            que já tenham realizado seu primeiro depósito na plataforma. Esses
            cupons serão claramente identificados e poderão ser utilizados
            apenas por usuários que atendam a essa condição.
          </p>
        </>
      </Accordion>

      <Accordion header="Limitação de Resgate de Bônus">
        <>
          <p>
            O Cartola Express poderá, a seu livre e exclusivo critério, limitar
            a quantidade de cupons de bônus que um usuário pode resgatar. Caso o
            usuário atinja o limite máximo estabelecido, a plataforma poderá
            impedir novos resgates, mesmo que o usuário cumpra os demais
            critérios ou ainda seja elegível para outras ações.
          </p>
        </>
      </Accordion>
    </Section>
  );
};

export default Coupon;
