import React from 'react';
import { scoreList } from './scoreList';
import { scoreListOtherCompetitions } from './scoreList-otherCompetitions';
import { Accordion, Collapse } from '@/modules/entenda-mais/components';
import * as S from './styles';
import { Section } from '@/modules/entenda-mais/layout';

const Score = (): JSX.Element => {
  const sectionDetails = {
    title: 'Scouts e pontuações ',
    // image: HowToPlayPicture02,
    text: [
      {
        paragraph: `O desempenho dos atletas a cada rodada dos campeonatos
        é transformado em pontos e computado para o(s) seu(s)
        time(s), de acordo com as regras abaixo.
        `,
      },
      {
        paragraph: `As pontuações consideradas são as que ocorrem apenas
        durante o tempo regulamentar da partida (incluindo
        acréscimos). No caso de disputas mata-mata, em que é
        possível a ocorrência de prorrogação e/ou disputa por
        pênaltis, qualquer ação dos atletas nesse período do jogo
        não contará pontos para o seu time.`,
      },
    ],
    index: 1,
  };
  return (
    <Section
      header={sectionDetails.title}
      text={sectionDetails.text}
      // image={sectionDetails.image}
      index={sectionDetails.index}
    >
      {/* <S.ScoreRulesHeader>Regras de pontuações</S.ScoreRulesHeader> */}
      <Accordion header="Pontuação Brasileirão Série A">
        <>
          <S.ScoreList>
            <S.ScoreListItem bgGrey={false}>
              <S.Row>
                <S.ScoreListHeader style={{ flex: '1' }}>
                  AÇÃO
                </S.ScoreListHeader>
                <S.ScoreListHeader>PONTOS</S.ScoreListHeader>
              </S.Row>
            </S.ScoreListItem>
            {scoreList.map((item, index: number) => (
              <S.ScoreListItem key={index} bgGrey={index % 2 === 0}>
                <S.Row>
                  <S.ScoreListAction style={{ flex: '1' }}>
                    {item.action}
                  </S.ScoreListAction>
                  <S.ScoreListAction>{item.score}</S.ScoreListAction>
                </S.Row>
                {item.text != '' && <S.TinyText>{item.text}</S.TinyText>}
              </S.ScoreListItem>
            ))}
          </S.ScoreList>
        </>
      </Accordion>
      <Accordion header="Pontuação exclusiva para goleiros, zagueiros e laterais para o Brasileirão Série A">
        <>
          <S.ScoreList style={{ marginBottom: '16px' }}>
            <S.ScoreListItem bgGrey={false}>
              <S.Row>
                <S.ScoreListHeader style={{ flex: '1' }}>
                  AÇÃO
                </S.ScoreListHeader>
                <S.ScoreListHeader>PONTOS</S.ScoreListHeader>
              </S.Row>
            </S.ScoreListItem>
            <S.ScoreListItem bgGrey={true}>
              <S.Row>
                <S.ScoreListAction style={{ flex: '1' }}>
                  Jogo sem sofrer gol (SG)
                </S.ScoreListAction>
                <S.ScoreListAction>+ 5,0 pontos</S.ScoreListAction>
              </S.Row>
            </S.ScoreListItem>
          </S.ScoreList>
        </>
      </Accordion>
      <Accordion header="Pontuação exclusiva para goleiros para o Brasileirão Série A">
        <>
          <S.Text>
            Defesa de pênalti e gol sofrido serão contabilizados caso o atleta
            de outras posições assuma a posição de goleiro no decorrer da
            partida.
          </S.Text>
          <S.ScoreList style={{ marginBottom: '16px' }}>
            <S.ScoreListItem bgGrey={false}>
              <S.Row>
                <S.ScoreListHeader style={{ flex: '1' }}>
                  AÇÃO
                </S.ScoreListHeader>
                <S.ScoreListHeader>PONTOS</S.ScoreListHeader>
              </S.Row>
            </S.ScoreListItem>
            <S.ScoreListItem bgGrey={true}>
              <S.Row>
                <S.ScoreListAction style={{ flex: '1' }}>
                  Defesa (DE)
                </S.ScoreListAction>
                <S.ScoreListAction>+ 1,0 ponto</S.ScoreListAction>
              </S.Row>
            </S.ScoreListItem>
            <S.ScoreListItem bgGrey={false}>
              <S.Row>
                <S.ScoreListAction style={{ flex: '1' }}>
                  Defesa de pênalti (DP)
                </S.ScoreListAction>
                <S.ScoreListAction>+ 7,0 pontos </S.ScoreListAction>
              </S.Row>
            </S.ScoreListItem>
            <S.ScoreListItem bgGrey={true}>
              <S.Row>
                <S.ScoreListAction style={{ flex: '1' }}>
                  Gol sofrido (GS)
                </S.ScoreListAction>
                <S.ScoreListAction>– 1,0 ponto</S.ScoreListAction>
              </S.Row>
            </S.ScoreListItem>
          </S.ScoreList>
          <S.TinyText>
            *Defesa de pênalti e gol sofrido serão contabilizados caso o atleta
            de outras posições assuma a posição de goleiro no decorrer da
            partida.
          </S.TinyText>
        </>
      </Accordion>
      <Accordion header="Revisões de Pontuação">
        <>
          <S.Text>
            Durante cada Partida, o Cartola Express recebe e revisa as
            pontuações ao vivo enviadas pelo fornecedor de scouts. Após o
            término de cada Partida, mediante recebimento das súmulas oficiais
            das respectivas confederações esportivas, os scouts e pontuações
            serão novamente revisados.
          </S.Text>
          <S.Text>
            Caso os fatos relatados na(s) súmula(s) das Partidas que compuseram
            uma Disputa sejam alterados após o processamento de resultados, as
            pontuações dos atletas não serão atualizadas e o resultado da
            Disputa não será alterado.
          </S.Text>
          <S.Text>
            Tal situação é diferente de uma correção que precise ser feita após
            o pagamento dos Valores dos Prêmios, devido às questões abaixo:
          </S.Text>
          <S.ScoreBox>
            <S.List>
              <li>
                <S.ListText>
                  Problemas técnicos no sistema Cartola Express
                </S.ListText>
              </li>
              <li>
                <S.ListText>
                  Erros nos processamentos de resultados da rodada pelo Cartola
                  Express.
                </S.ListText>
              </li>
            </S.List>
          </S.ScoreBox>
          <S.Text>
            Nestes casos, esse processo (incluindo o pagamento do Valor do
            Prêmio, quando aplicável) pode ser revertido e as Disputas podem
            ganhar um novo resultado.
          </S.Text>
        </>
      </Accordion>
      <Accordion header="Demais competições esportivas">
        <>
          <p>
            Competições que consideram esses scouts: Campeonato Paulista,
            Libertadores, Copa do Brasil, Copas do Mundo FIFA (Masculino e
            Feminino), Campeonatos Inglês, Espanhol e Italiano, Eliminatórias da
            Copa do Mundo FIFA™ 2026, Eliminatórias Eurocopa 2024.
          </p>
          <S.ScoreList>
            <S.ScoreListItem bgGrey={false}>
              <S.Row>
                <S.ScoreListHeader style={{ flex: '1' }}>
                  SCOUT
                </S.ScoreListHeader>
                <S.ScoreListHeader style={{ width: '40%' }}>
                  POSIÇÃO
                </S.ScoreListHeader>
                <S.ScoreListHeader style={{ width: '20%' }}>
                  PONTOS
                </S.ScoreListHeader>
              </S.Row>
            </S.ScoreListItem>
            {scoreListOtherCompetitions.map((item, index: number) => (
              <S.ScoreListItem key={index} bgGrey={index % 2 === 0}>
                <S.Row>
                  <S.ScoreListAction style={{ flex: '1' }}>
                    {item.action}
                  </S.ScoreListAction>
                  <S.ScoreListAction style={{ width: '40%' }}>
                    {item.position}
                  </S.ScoreListAction>
                  <S.ScoreListAction style={{ width: '20%' }}>
                    {item.score}
                    {Math.abs(Number(item.score)) > 1 ? ' pontos' : ' ponto'}
                  </S.ScoreListAction>
                </S.Row>
              </S.ScoreListItem>
            ))}
          </S.ScoreList>

          <S.Text>
            <b>Gol:</b> o gol concede pontuações diferentes a depender da
            posição do Atleta:
          </S.Text>

          <S.Text>
            <S.ListText>
              <S.List>
                <li>
                  <S.ListText>Atacante: 4 pontos</S.ListText>
                </li>
                <li>
                  <S.ListText>Meias: 5 pontos</S.ListText>
                </li>
                <li>
                  <S.ListText>Defensores: 6 pontos</S.ListText>
                </li>
                <li>
                  <S.ListText>Goleiro: 8 pontos</S.ListText>
                </li>
              </S.List>
            </S.ListText>
          </S.Text>

          <S.Text>
            <b>Assistência:</b> 3 pontos
          </S.Text>

          <S.Text>
            A assistência é concedida ao atleta que faz o passe final antes de
            um gol ser marcado, independentemente de o passe ter sido ou não
            intencional. Todas as posições recebem 3 pontos pela assistência.
          </S.Text>

          <S.Text>
            <b>Observação sobre Gols, gols contra e assistências</b>
          </S.Text>

          <S.Text>
            Seguimos as decisões oficiais das ligas sobre quem marcou/assistiu,
            a menos que tenhamos 100% de certeza de que a súmula está incorreta.
          </S.Text>

          <S.Text>
            Às vezes, as decisões oficiais mudam após o término da partida. Para
            que possamos pagar os ganhos de um torneio dentro de um prazo
            razoável, as alterações feitas no resultado oficial{' '}
            <b>20 minutos ou mais</b> após o término da partida não serão
            levadas em consideração.
          </S.Text>

          <S.Text>
            <b>Assistência de Fantasy </b>
          </S.Text>
          <S.Text>
            A Assistência de Fantasy é concedida nas seguintes situações:
          </S.Text>

          <S.Text>
            <S.ListText>
              <S.List>
                <li>
                  <S.ListText>
                    O jogador realiza o último passe antes de um gol, mas a bola
                    é desviada não-intencionalmente por um adversário antes de
                    chegar ao marcador;
                  </S.ListText>
                </li>
                <li>
                  <S.ListText>
                    O jogador sofre um pênalti que resulta em um gol.
                  </S.ListText>
                </li>
                <li>
                  <S.ListText>
                    O jogador sofre uma falta cuja cobrança resulta em um gol
                    através de tiro direto
                  </S.ListText>
                </li>
                <li>
                  <S.ListText>
                    O jogador realiza uma finalização na trave, com rebote sendo
                    convertido em gol por outro jogador
                  </S.ListText>
                </li>
                <li>
                  <S.ListText>
                    O jogador força um adversário a fazer um gol contra
                  </S.ListText>
                </li>
                <li>
                  <S.ListText>
                    Um jogador que realiza finalização defendida pelo goleiro
                    adversário, que gera um rebote convertido em gol, recebe a
                    Assistência de Fantasy.
                  </S.ListText>
                </li>
              </S.List>
            </S.ListText>
          </S.Text>

          <S.Text>
            Observação importante: A Assistência de Fantasy é computada
            normalmente como “Assistência” no sistema do Cartola Express,
            concedendo 3 pontos.
          </S.Text>

          <S.Text>
            <b>Jogo sem sofrer gols (60+ mins em campo)</b>
          </S.Text>
          <S.Text>
            Para um jogador receber essa pontuação, ele precisa jogar pelo menos
            60 minutos e não sofrer nenhum gol enquanto estiver em campo.
            Observe que se um jogador for substituído no 60º minuto de uma
            partida, por exemplo, aos 59:30, ele não jogou 60 minutos e não
            receberá a pontuação, embora os relatórios da partida provavelmente
            sejam relatando 60&apos;. Meias, defensores e goleiros receberão
            pontuações diferentes pelo jogo sem sofrer gols:
          </S.Text>

          <S.Text>
            <S.ListText>
              <S.List>
                <li>
                  <S.ListText>
                    <b>Defensores e goleiros:</b> +4 pontos
                  </S.ListText>
                </li>
                <li>
                  <S.ListText>
                    <b>Meias:</b> +1 ponto
                  </S.ListText>
                </li>
              </S.List>
            </S.ListText>
          </S.Text>

          <S.Text>
            <b>Defesa:</b> +0,5 pontos (apenas goleiros)
          </S.Text>

          <S.Text>
            <b>Defesa de pênalti:</b> +5 pontos (apenas goleiros)
          </S.Text>
          <S.Text>
            A defesa de pênalti é computada se o goleiro realmente defender o
            pênalti. Caso o adversário erre o alvo ou acerte a trave ou
            travessão sem que o goleiro toque na bola, o scout não será
            computado.
          </S.Text>

          <S.Text>
            <b>2 Gols sofridos:</b> -1 ponto (apenas goleiros e defensores)
          </S.Text>

          <S.Text>
            <b>Tempo em campo:</b> até +3 pontos
          </S.Text>
          <S.Text>
            Os jogadores de todas as posições receberão pontos pelos minutos em
            campo, com a seguinte regra:
          </S.Text>

          <S.Text>
            <S.ListText>
              <S.List>
                <li>
                  <S.ListText>
                    <b>Entrou em campo:</b> 1 ponto (todas as posições)
                  </S.ListText>
                </li>
                <li>
                  <S.ListText>
                    <b>Jogou mais de 60 minutos:</b> 2 pontos (todas as
                    posições)
                  </S.ListText>
                </li>
                <li>
                  <S.ListText>
                    <b>Jogou a partida toda:</b> 3 pontos (apenas meias e
                    atacantes)
                  </S.ListText>
                </li>
              </S.List>
            </S.ListText>
          </S.Text>

          <S.Text>
            <b>Resultado em Campo</b>
          </S.Text>
          <S.Text>
            Os jogadores receberão ou perderão pontos pelo resultado parcial de
            seu time enquanto eles estiverem em campo:
          </S.Text>

          <S.Text>
            <S.ListText>
              <S.List>
                <li>
                  <S.ListText>
                    <b>Vitória em campo:</b> +0,3 pontos
                  </S.ListText>
                </li>
                <li>
                  <S.ListText>
                    <b>Derrota em campo:</b> -0,3 pontos
                  </S.ListText>
                </li>
              </S.List>
            </S.ListText>
          </S.Text>

          <S.Text>
            <b>Finalização defendida</b>
          </S.Text>
          <S.Text>
            Os atletas receberão pontuações diferentes pela finalização
            defendida dependendo da sua posição:
          </S.Text>

          <S.Text>
            <S.ListText>
              <S.List>
                <li>
                  <S.ListText>
                    <b>Goleiros:</b> +1 ponto
                  </S.ListText>
                </li>
                <li>
                  <S.ListText>
                    <b>Defensores:</b> +0,6 pontos
                  </S.ListText>
                </li>
                <li>
                  <S.ListText>
                    <b>Meias e Atacantes:</b> +0,4 pontos
                  </S.ListText>
                </li>
              </S.List>
            </S.ListText>
          </S.Text>

          <S.Text>
            <b>Cartão amarelo:</b> -1 ponto
          </S.Text>

          <S.Text>
            <b>Cartão vermelho:</b> -3 pontos
          </S.Text>

          <S.Text>
            <b>Observação sobre cartões:</b>
          </S.Text>
          <S.Text>
            Atletas que receberem cartões amarelos/vermelhos perderão pontos
            independentemente de estarem em campo ou no banco de reservas. A
            partir do momento em que o árbitro finalizar a partida (tempo normal
            + acréscimos), não serão computados pontos para os cartões, dado que
            cartões recebidos não poderão mais afetar o resultado do jogo.
          </S.Text>

          <S.Text>
            <b>Falta cometida que provoca gol:</b> -2 pontos
          </S.Text>

          <S.Text>
            <b>Pênalti cometido:</b> -2 pontos
          </S.Text>

          <S.Text>
            <b>Pênalti perdido:</b> -2 pontos (qualquer pênalti que não resulte
            em gol direto)
          </S.Text>

          <S.Text>
            <b>Gol contra:</b> -2 pontos
          </S.Text>

          <S.Text>
            Após o recebimento das pontuações finais do fornecedor de scouts, o
            Cartola Express inicia o processamento de resultados e pagamento dos
            valores dos prêmios. Para competições além do Brasileirão Série A, o
            Cartola Express não revisará as pontuações indicadas pelo fornecedor
            de scouts.
          </S.Text>
          <S.Text>
            Se houver mudanças de pontuação oriundas de fatos formalizados na
            súmula em até 20 minutos após recebimento das pontuações do
            fornecedor de scouts, as novas pontuações serão consideradas e o
            Cartola Express poderá realizar um reprocessamento de resultados e
            correções na distribuição dos prêmios.
          </S.Text>
          <S.Text>
            Caso os fatos relatados na(s) súmula(s) das Partidas que compuseram
            uma Disputa sejam alterados após o processamento de resultados, as
            pontuações dos atletas não serão atualizadas e o resultado da
            Disputa não será alterado.
          </S.Text>

          <S.Text>
            Tal situação é diferente de uma correção que precise ser feita após
            o pagamento dos Valores dos Prêmios, devido às questões abaixo:
          </S.Text>
          <S.ScoreBox>
            <S.List>
              <li>
                <S.ListText>
                  Problemas técnicos no sistema Cartola Express
                </S.ListText>
              </li>
              <li>
                <S.ListText>
                  Erros nos processamentos de resultados da rodada pelo Cartola
                  Express.
                </S.ListText>
              </li>
            </S.List>
          </S.ScoreBox>
          <S.Text>
            Nestes casos, esse processo (incluindo o pagamento do Valor do
            Prêmio, quando aplicável) pode ser revertido e as Disputas podem
            ganhar um novo resultado.
          </S.Text>
        </>
      </Accordion>
      <Accordion header="Casos Inesperados">
        <>
          <Collapse header="1. Partidas adiadas ou canceladas ">
            <S.Text>
              Em algumas situações, o adiamento ou cancelamento de partidas
              esportivas incluídas em Disputas pode causar o cancelamento de
              Disputas no Cartola Express, a nosso exclusivo critério.
            </S.Text>
            <S.Text>
              Se uma partida incluída for cancelada por qualquer motivo,
              qualquer atleta selecionado para aquela partida não pontuará.
            </S.Text>

            <S.Text>
              Se uma partida incluída for adiada por qualquer motivo, o Cartola
              Express decidirá sobre o cancelamento ou não das Disputas que
              incluem a partida adiada, a nosso exclusivo critério.
            </S.Text>
            <S.Text>
              Se o cancelamento e/ou adiamento de uma partida significar que só
              resta uma partida válida em uma disputa, isto implicará no
              cancelamento da Disputa e das Inscrições, quando aplicável. Caso
              esta hipótese ocorra e você esteja participando de uma disputa com
              inscrição, o saldo da sua carteira será atualizado, sendo certo
              que quaisquer valores que tenham sido reservados na sua carteira
              para a sua inscrição nas disputas com inscrições serão novamente
              disponibilizados a você.
            </S.Text>
            <S.Text>
              Devido à incerteza em torno de adiamentos das partidas por
              quaisquer alterações nos calendários dos campeonatos
              correspondentes, o Cartola Express poderá fazer alterações a estas
              regras.
            </S.Text>
          </Collapse>
          <Collapse header="2. Partidas Suspensas ">
            <S.Text>
              Se uma Partida for{' '}
              <b>suspensa ou interrompida por qualquer motivo</b>, as
              estatísticas acumuladas até o ponto de suspensão contarão para as
              disputas.
            </S.Text>
            <S.Text>
              Caso a partida seja concluída em uma data próxima da última
              partida programada da mesma rodada do campeonato em questão, o
              Cartola Express poderá, a seu exclusivo critério, decidir sobre a
              contabilização ou não das estatísticas para as Disputas.
            </S.Text>
          </Collapse>
          <Collapse header="3. Cancelamento da disputa">
            <S.Text>
              Em caso de cancelamento das Disputas com Inscrições, o Saldo da
              sua Carteira será atualizado, sendo certo que quaisquer valores
              que tenham sido reservados na sua Carteira para a sua Inscrição na
              Disputa serão novamente disponibilizados a Você. O cancelamento
              pode acontecer em situações como:
            </S.Text>
            <S.List>
              <li>
                <S.ListText>Problemas técnicos;</S.ListText>
              </li>
              <li>
                <S.ListText>
                  Situações externas que impactem os eventos esportivos.
                </S.ListText>
              </li>
            </S.List>
          </Collapse>
        </>
      </Accordion>
      <Accordion header="Seção “Histórico”">
        <>
          {/* <S.ImageFullLength src={Historic} /> */}
          <S.Text>
            Você encontra o seu Histórico de participações em disputas na seção
            “Meus Times”. No Histórico, você poderá consultar todo o histórico
            de disputas de que participou. As seguintes informações sobre essas
            disputas estão disponíveis: nome da disputa, campeonato, times
            escalados, pontuações, valores de inscrições, quantidades de
            inscrições (times), valores dos prêmios recebidos, entre outras
            informações.
          </S.Text>
          <S.Text>
            Você também poderá clicar em cada uma das Disputas e visualizar mais
            detalhes, como o ranking completo da Disputa, que também mostra
            resultados de todos os outros jogadores participantes.
          </S.Text>
        </>
      </Accordion>
    </Section>
  );
};

export default Score;
