import { device } from '@/pages/main/styles';
import styled from 'styled-components';

interface StyledProps {
  bgGrey?: boolean;
  isBold?: boolean;
}

export const ScoreRulesHeader = styled.h3`
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  font-family: ${(props) => props.theme.fonts.opensansBold};
  line-height: 24px;
  margin-bottom: 16px;
`;

export const ScoreList = styled.ul`
  margin: 0px 0 16px;
  padding: 0px;
`;

export const ScoreBox = styled.div`
  background-color: rgba(233, 233, 233, 0.5);
  padding: 16px;
  margin: 0 0 16px;
  border-radius: 4px;
`;

export const ImageFullLength = styled.img`
  margin: 30px auto;
  width: 30.5em;

  @media ${device.mobileL} {
    margin: 16px 0;
    width: auto;
  }
`;

export const ScoreListHeader = styled.span`
  margin: 0px;
  padding: 0px;
  font-weight: bold;
  font-size: 20px;
  font-family: ${(props) => props.theme.fonts.opensansBold};
  line-height: 24px;
  color: ${(props) => props.theme.colors.orange};
`;

export const ScoreListItem = styled.li<StyledProps>`
  display: flex;
  flex-direction: column;
  padding: 22px 8px;
  background: ${(props) =>
    props.bgGrey ? 'rgba(233, 233, 233, 0.25)' : 'none'};
`;

export const ScoreListAction = styled.span<StyledProps>`
  ${(props) => {
    if (props.isBold) {
      return `
        font-size: 20px;
        line-height: 24px;
        font-family: ${props.theme.fonts.opensansBold};
        text-transform: uppercase;
        color: ${props.theme.colors.orange};
      `;
    }
    return `
      font-size: 16px;
      line-height: 24px;
    `;
  }}
`;

export const Text = styled.p`
  font-size: 20px;
  line-height: 28px;
  margin: 0px 0px 16px 0px;
  @media ${device.mobileL} {
    font-size: 16px;
  }
`;

export const TinyText = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.grey};
  margin: 10px 0px 16px 0px;
`;

export const ListText = styled.p`
  font-size: 20px;
  line-height: 28px;
  margin: 0px;
  @media ${device.mobileL} {
    font-size: 16px;
  }
`;

export const List = styled.ul`
  list-style-type: disc;
  list-style-position: outside;
  margin: 0px 0 0 35px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
