import React from 'react';
import { Accordion } from '@/modules/entenda-mais/components';
import * as S from './styles';
import { Section } from '@/modules/entenda-mais/layout';

const ExpressWebComponentDuvidas = (): JSX.Element => {
  const sectionDetail = {
    title: 'Dúvidas frequentes',
    index: 0,
  };

  return (
    <S.DuvidasContainer>
      <Section header={sectionDetail.title} index={sectionDetail.index}>
        <Accordion header="Como criar sua conta no Cartola Express">
          <>
            <p>
              Você pode usar sua conta do Cartola ou criar uma nova, é só
              aceitar os termos de uso e completar seu cadastro, se necessário.
            </p>
          </>
        </Accordion>
        <Accordion header="Como adicionar saldo na sua carteira?">
          <>
            <S.Text>
              Clique em &quot;adicionar saldo&quot; no lobby do Cartola Express.
              Em seguida, digite o valor desejado no campo disponível e clique
              em &quot;gerar código pix&quot;.{' '}
            </S.Text>
            <S.Text>
              No seu app pagamentos, escola a opção &quot;pagar via pix&quot;.
              Com a câmera do celular, você pode escanear o QR code que
              aparecerá na tela do computador ou realizar a transação na opção
              &quot;PIX copia e cola&quot;.
            </S.Text>
            <S.Text>
              Pronto! Em instantes, você verá no Cartola Express o seu novo
              Saldo e a confirmação da transação na aba &quot;Transações&quot;.
            </S.Text>
          </>
        </Accordion>
        <Accordion header="Como ganhar e resgatar o bônus no Cartola Express?">
          <>
            <S.Text>
              No Cartola Express, existem diversas opções de bônus que você pode
              ganhar. Você pode verificar todas as opções na Central de
              Recompensas. Para acessar, siga o passo a passo abaixo:
            </S.Text>
            <S.List>
              <li>
                <S.ListText>
                  1. Clique no seu perfil, no menu superior da página principal;
                </S.ListText>
              </li>
            </S.List>
            <S.List>
              <li>
                <S.ListText>
                  2. Clique em &quot;Central de Recompensas&quot;
                </S.ListText>
              </li>
            </S.List>
            <S.List>
              <li>
                <S.ListText>
                  3. Pronto! Você poderá ver os bônus disponíveis, seus status e
                  como resgatar.
                </S.ListText>
              </li>
            </S.List>
            <p> </p>
            <S.Text>
              Após o resgate, o valor cairá em sua carteira em até 24h.
            </S.Text>
            <p> </p>
            <S.Text>
              Ah! Fique de olho em nossas redes sociais para ganhar cupons
              exclusivos. Eles também são resgatados na Central de Recompensas.
              Siga o passo a passo:
            </S.Text>

            <S.List>
              <li>
                <S.ListText>
                  1. Clique no seu perfil, no menu superior da página principal;
                </S.ListText>
              </li>
            </S.List>
            <S.List>
              <li>
                <S.ListText>
                  2. Clique em &quot;Central de Recompensas&quot;;
                </S.ListText>
              </li>
            </S.List>
            <S.List>
              <li>
                <S.ListText>
                  3. Clique em “Resgate de Cupom”: uma caixa de texto aparecerá;
                </S.ListText>
              </li>
            </S.List>
            <S.List>
              <li>
                <S.ListText>
                  4. Digite seu cupom e clique em “Resgatar”.
                </S.ListText>
              </li>
            </S.List>
          </>
        </Accordion>
        <Accordion header="Como escalar seu time quando o mercado estiver aberto?">
          <>
            <S.Text>
              Quando o mercado estiver aberto, você pode mudar o seu time
              quantas vezes quiser! Basta entrar em Meus Times no menu superior
              e clicar no botão Editar Escalação.
            </S.Text>

            <S.Text>
              Você pode fazer uma mesma escalação para todas as disputas que
              estiver inscritos ou variar o seu time em campo de acordo com cada
              disputa. A escolha é sua!
            </S.Text>
          </>
        </Accordion>
      </Section>
    </S.DuvidasContainer>
  );
};

export default ExpressWebComponentDuvidas;
