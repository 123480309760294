import React, { useState, useEffect } from 'react';
import IndexIcon from '../../../assets/icons/index-icon.svg';
import { navItems } from '../navSideBar';
import useEventListener from '@/hooks/useEventListener';
import * as S from './styles';

interface DefaultProps {
  valueIndex: number;
}

const MobileSideBar = ({ valueIndex }: DefaultProps): JSX.Element => {
  const [itemActive, setItemActive] = useState(0);
  const [showMenu, setShowMenu] = useState(false);
  const [menuScroll, setMenuScroll] = useState(false);

  const handleScroll = (): void => {
    if (window.pageYOffset > 50) {
      setMenuScroll(true);
    } else {
      setMenuScroll(false);
    }
  };

  useEventListener('scroll', handleScroll);

  useEffect(() => {
    setItemActive(Number(valueIndex));
  }, [valueIndex]);

  return (
    <S.NavContainer>
      {showMenu && (
        <S.NavGroup>
          {navItems.map((item: string, index: number) => (
            <S.NavGroupItem
              key={item}
              itemActive={itemActive === index}
              onClick={() => setItemActive(index)}
            >
              <S.NavGroupItemSelected itemActive={itemActive === index} />
              <S.NavGroupItemText
                onClick={() => setShowMenu(false)}
                href={`#${item
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f\s]/g, '')}`}
              >
                {item}
              </S.NavGroupItemText>
            </S.NavGroupItem>
          ))}
        </S.NavGroup>
      )}
      <S.NavIndex onClick={() => setShowMenu(!showMenu)}>
        {!menuScroll && <S.NavHeader>Índice</S.NavHeader>}
        <S.NavImage src={IndexIcon} />
      </S.NavIndex>
    </S.NavContainer>
  );
};

export { MobileSideBar };
