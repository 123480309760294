import useWindowSize from '@/hooks/useWindowSize';
import React from 'react';
import * as S from './styles';

interface Props {
  header?: string;
  image?: string;
  text?: { paragraph: string }[];
  children?: JSX.Element | JSX.Element[];
  index?: number;
}

const Section = ({
  header,
  image,
  children,
  text,
  index,
}: Props): JSX.Element => {
  const { width } = useWindowSize();
  const isMobileSize = width <= 1100;

  return (
    <S.SectionContainer
      mobileSize={isMobileSize}
      id={header?.normalize('NFD').replace(/[\u0300-\u036f\s]/g, '')}
      data-index={index}
    >
      <S.SectionHeader>{header}</S.SectionHeader>
      {header !== 'Glossário' && <hr />}
      <S.SectionImage src={image} />
      {text?.map(({ paragraph }, index: number) => (
        <S.SectionText key={index}>{paragraph}</S.SectionText>
      ))}
      {children}
    </S.SectionContainer>
  );
};

export { Section };
