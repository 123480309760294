declare global {
  interface Window {
    dataLayer: any;
    ga: any;
  }
}

// const sendGAEvent = (
//   eventCategory: string,
//   eventAction: string,
//   eventLabel: string,
// ) => {
//   if (window.ga) {
//     window.ga('send', 'event', {
//       eventCategory,
//       eventAction,
//       eventLabel,
//     });
//     console.log(
//       'GA - ga sendEvent: eventCategory, eventAction, eventLabel --> ',
//       eventCategory,
//       eventAction,
//       eventLabel,
//     );
//   } else {
//     console.warn('Não tem ga!!');
//   }
// };

const sendGTMEvent = (
  eventCategory: string,
  eventAction: string,
  eventLabel: string,
): void => {
  if (window.dataLayer && eventCategory.length) {
    window.dataLayer.push({
      event: 'eventTracking',
      eventCategory: eventCategory,
      eventAction: eventAction,
      eventLabel: eventLabel,
    });
    console.log(
      'GTM - dataLayer sendEvent: eventCategory, eventAction, eventLabel --> ',
      eventCategory,
      eventAction,
      eventLabel,
    );
  } else {
    console.error('* GTM datalayer não definido.');
  }
};

const sendGAPageView = (
  pathname: string,
  globoId?: string,
  origemId?: string,
): void => {
  if (window.ga) {
    if (globoId) {
      window.ga('set', 'dimension96', 'globoId');
      window.ga('set', 'dimension99', globoId);
    }

    if (origemId) {
      window.ga('set', '', origemId);
    }

    window.ga('set', 'dimension1', 'web');
    window.ga('send', 'pageview', pathname || window.location.hash);
    console.log(
      'GA - ga sendPageView: pathname --> ',
      pathname || window.location.hash,
    );
  } else {
    console.log('* GA não definido.');
  }
};

const sendEvent = (
  eventCategory: string,
  eventAction: string,
  eventLabel: string,
): void => {
  sendGTMEvent(eventCategory, eventAction, eventLabel);
  //sendGAEvent(eventCategory, eventAction, eventLabel);
};

export { sendEvent, sendGAPageView };
