import styled from 'styled-components';
import { device } from '@/pages/main/styles';

interface StyledProps {
  mobileSize?: boolean;
}

export const HeaderContainer = styled.div<StyledProps>`
  display: flex;
  justify-content: center;
  padding: 32px 0px;
  align-items: center;
  background-color: ${(props) => props.theme.colors.white};
  position: relative;
`;

export const HeaderText = styled.h1<StyledProps>`
  text-align: ${(props) => !props.mobileSize && 'center'};
  font-size: 20px;
  line-height: 24px;
  font-family: ${(props) => props.theme.fonts.opensansBold};
  color: ${(props) => props.theme.colors.orange};
  margin: 0px;
`;

export const HeaderButton = styled.div`
  position: absolute;
  left: 32px;
  margin-top: 0.25em;
  cursor: pointer;

  @media ${device.mobileL} {
    left: 24px;
  }
`;
