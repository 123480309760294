import styled from 'styled-components';

interface StyledProps {
  itemActive: boolean;
}

export const NavContainer = styled.div`
  display: flex;
  position: fixed;
  margin-top: -60px;
  right: 0px;
  z-index: 1000;
`;

export const NavIndex = styled.div`
  display: flex;
  height: 40px;
  padding: 0px 8px;
  border: 1px solid ${(props) => props.theme.colors.greyLightSideBar};
  border-radius: 6px 0px 0px 6px;
  align-items: center;
  justify-content: space-around;
  background: ${(props) => props.theme.colors.white};
`;

export const NavImage = styled.img`
  width: 24px;
  height: 24px;
`;

export const NavHeader = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: ${(props) => props.theme.colors.orange};
`;

export const NavGroup = styled.ul`
  margin: 0px 10px;
  padding: 0px;
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.greyLightSideBar};
  box-sizing: border-box;
  border-radius: 6px;
  padding: 16px;
`;

export const NavGroupItem = styled.li<StyledProps>`
  display: flex;
  align-items: center;
  cursor: pointer;
  opacity: ${(props) => (props.itemActive ? '1' : '.25')};
  margin: 0px 10px 24px 10px;

  &:last-child {
    margin-bottom: 0px;
  }
`;

export const NavGroupItemText = styled.a`
  font-size: 14px;
  line-height: 24px;
  margin: 0px 10px;
  color: ${(props) => props.theme.colors.black};
`;

export const NavGroupItemSelected = styled.span<StyledProps>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.itemActive ? props.theme.colors.orange : 'none'};
`;
