import { theme } from './theme.style';

// TO-DO: Refatorar max-width para min-width
export const device = {
  mobileS: `(max-width: ${theme.breakpoints.mobileS})`,
  mobileM: `(max-width: ${theme.breakpoints.mobileM})`,
  mobileL: `(max-width: ${theme.breakpoints.mobileL})`,
  tabletMin: `(min-width: ${theme.breakpoints.tablet})`,
  tablet: `(max-width: ${theme.breakpoints.tablet})`,
  tabletLMin: `(min-width: ${theme.breakpoints.tabletL})`,
  tabletL: `(max-width: ${theme.breakpoints.tabletL})`,
  desktop: `(max-width: ${theme.breakpoints.desktop})`,
  desktopMin: `(min-width: ${theme.breakpoints.desktop})`,
};
