export const scoreList = [
  {
    scout: 'Partida Vencida',
    score: '+2.5',
  },
  {
    scout: 'Ace',
    score: '+0.3',
  },
  {
    scout: 'Dupla Falta',
    score: '-0.5',
  },
  {
    scout: 'Game conquistado pelo recebedor',
    score: '+1.5',
  },
  {
    scout: 'Game perdido pelo sacador',
    score: '-0.6',
  },
  {
    scout: 'Bônus de mais de 10 Aces',
    score: '+1.5',
  },
  {
    scout: 'Bônus: Vitória sem perder Set',
    score: '0.5x',
  },
  {
    scout: 'Set vencido por Tie Break',
    score: '+10.0',
  },
  {
    scout: 'Set vencido por 2 games de diferença',
    score: '+12.0',
  },
  {
    scout: 'Set vencido por 3 games de diferença',
    score: '+14.0',
  },
  {
    scout: 'Set vencido por 4 games de diferença',
    score: '+16.0',
  },
  {
    scout: 'Set vencido por 5 games de diferença',
    score: '+16.5',
  },
  {
    scout: 'Set vencido por 6 games de diferença',
    score: '+18.0',
  },
  {
    scout: 'Set perdido por Tie Break',
    score: '+8.0',
  },
  {
    scout: 'Set perdido por 2 games de diferença',
    score: '+6.0',
  },
  {
    scout: 'Set perdido por 3 games de diferença',
    score: '+3.5',
  },
  {
    scout: 'Set perdido por 4 games de diferença',
    score: '+2.5',
  },
  {
    scout: 'Set perdido por 5 games de diferença',
    score: '+1.5',
  },
  {
    scout: 'Set perdido por 6 games de diferença',
    score: '-1.5',
  },
];
