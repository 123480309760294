import React, { useState, useEffect } from 'react';
import IndexIcon from '../../../assets/icons/index-icon.svg';
import * as S from './styles';

interface DefaultProps {
  valueIndex: number;
}

export const navItems = [
  'Quer jogar o Cartola Express? Saiba como funciona!',
  'Scouts e pontuações',
  'Processamento de resultados e Pagamento do Valor do Prêmio',
  'Cupom',
  'Movimentações financeiras',
  'Automobilismo',
  'Basquete',
  'Futebol Americano',
  'Tênis',
];

const NavSidebar = ({ valueIndex }: DefaultProps): JSX.Element => {
  const [itemActive, setItemActive] = useState(0);

  useEffect(() => {
    setItemActive(Number(valueIndex));
  }, [valueIndex]);

  return (
    <S.Nav>
      <S.NavIndex>
        <img src={IndexIcon} alt="Ícone do Menu Hamburguer" />
        <S.NavHeader>Índice</S.NavHeader>
      </S.NavIndex>
      <S.NavGroup>
        {navItems.map((item: string, index: number) => (
          <S.NavGroupItem
            key={item}
            itemActive={itemActive === index}
            onClick={() => setItemActive(index)}
          >
            <S.NavGroupItemSelected itemActive={itemActive === index} />
            <S.NavGroupItemText
              href={`#${item
                .normalize('NFD')
                .replace(/[\u0300-\u036f\s]/g, '')}`}
            >
              {item}
            </S.NavGroupItemText>
          </S.NavGroupItem>
        ))}
      </S.NavGroup>
    </S.Nav>
  );
};

export { NavSidebar };
