import React, { useEffect } from 'react';

import { Header } from '@/layout/header';
import * as S from './styles';
import ExpressLogo from '@/modules/express/assets/img/logo_express_white.svg';
import { WhiteButtonFilled } from '@/modules/express/components/button';
import { HeaderTop } from '@/modules/express/components/header';
import { UnderstandMorePage } from '../../../entenda-mais/pages/understandMorePage';

interface Props {
  callFanduel: () => void;
}

const Home = ({ callFanduel }: Props): JSX.Element => {
  const [callbackActionToExpress, setCallbackActionToExpress] =
    React.useState<boolean>(false);

  const confirmaAcessoAoExpress = () => {
    setCallbackActionToExpress(true);
  };

  useEffect(() => {
    if (callbackActionToExpress) {
      callFanduel();
    }
  }, [callbackActionToExpress]);

  return (
    <>
      <HeaderTop callFanduel={callFanduel} header={false} subHeader={true}>
        <>
          <img src={ExpressLogo} alt="Cartola Express Logo" />
          <WhiteButtonFilled
            label="Ir para o jogo"
            onClick={confirmaAcessoAoExpress}
          />
        </>
      </HeaderTop>
      <S.Container>
        <Header text="Entenda Mais" />
        <S.SectionDisplay>
          <UnderstandMorePage />
        </S.SectionDisplay>
      </S.Container>
    </>
  );
};

export default Home;
