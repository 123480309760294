import { device } from '@/pages/main/styles';
import styled from 'styled-components';

interface StyledProps {
  mobileSize?: boolean;
}

export const SectionContainer = styled.section<StyledProps>`
  display: flex;
  flex-flow: column;
  width: 43em;
  margin: 0 auto;
  padding-top: 40px;

  @media ${device.mobileL} {
    width: 20.5em;
  }

  hr {
    width: 100%;
    border-color: #e9e9e9;
    margin-bottom: 16px;
    opacity: 0.25;
  }
`;

export const SectionHeader = styled.h1`
  font-family: ${(props) => props.theme.fonts.opensansBold};
  color: #333333;
  margin-bottom: 16px;
`;

export const SectionText = styled.p`
  margin-bottom: 64px;
  @media ${device.mobileL} {
    font-size: 16px;
    margin-bottom: 24px;
  }
`;

export const SectionImage = styled.img`
  border: 4px;
  margin: 0 0px 16px;
  border-radius: 12px;
`;
