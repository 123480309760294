import { useEffect, useState } from 'react';
// https://foundation.zurb.com/sites/docs/media-queries.html
// Small: any screen.
// Medium: any screen 640 pixels or wider.
// Large: any screen 1024 pixels or wider.

export const useMedia = (queries?: any, defaultValue = 'small') => {
  const [value, setValue] = useState(defaultValue);
  const queriesLocal = queries || {
    small: window.matchMedia('(min-width: 0px) and (max-width: 639px)'),
    medium: window.matchMedia('(min-width: 640px) and (max-width: 1023px)'),
    large: window.matchMedia('(min-width: 1024px)'),
  };
  const values = Object.keys(queriesLocal)
    .map((q: any) => ({ name: q, matches: queriesLocal[q].matches }))
    .filter((i: any) => i.matches);

  const getValue = () => {
    setValue((values && values.length && values[0].name) || defaultValue);
  };

  useEffect(() => {
    getValue();
    const handler = () => setValue(values && values.length && values[0].name);
    Object.keys(queriesLocal).forEach((q) =>
      queriesLocal[q].addListener(handler),
    );
    return () =>
      Object.keys(queriesLocal).forEach((q) =>
        queriesLocal[q].removeListener(handler),
      );
  }, []);

  return value;
};
