import React, { useEffect, useState } from 'react';
import Timer from '../timer';
import { useMedia } from '../../../../hooks';
import * as phone from '../../assets/img';
import { Icon14, Icon15 } from '../../assets/icons';
import './how-to-play.style.scss';

interface StringArray {
  [index: number]: string;
}

const ExpressWebComponentHowToPlay = (): JSX.Element => {
  const playList = [
    {
      title: 'Crie sua conta no Cartola Express',
      text: 'Você pode usar sua conta Globo ou, caso não tenha, criar uma nova. Em seguida, defina seu nome de usuário no Cartola Express.',
    },
    {
      title: 'Adicione saldo na sua carteira',
      text: 'Para começar a jogar, você pode entrar em uma disputa grátis ou paga. Se escolher uma disputa paga, é preciso ter saldo na sua conta.',
    },
    {
      title: 'Escale seu time',
      text: `No Express, não há valorização de cartoletas. Todos têm o mesmo número de cartoletas por time escalado. Você pode escalar diversos times nas disputas multi e aumentar as suas chances.`,
    },
    {
      title: 'Ganhe premiações em dinheiro',
      text: `No final da rodada ou jogo único, os vencedores de cada disputa ganham prêmios que podem ser sacados ou usados para entrar em novas disputas.`,
    },
  ] as {
    title: string;
    text: string;
    subText?: string;
  }[];

  const loop = true;
  const total = 4;
  const [currentElement, setElement] = useState(0);
  const media = useMedia();
  const imagePhone: any = phone;
  const PhoneStep: StringArray = [`PhoneStep${currentElement}`];
  const arrImages: any = PhoneStep;

  const handleNextItem = (): void => {
    if (currentElement === total - 1) {
      setElement(loop ? 0 : currentElement);
    } else {
      setElement(currentElement + 1);
    }
  };

  const handleLoopNextItem = (): void => {
    if (currentElement < total) {
      handleNextItem();
    }
  };

  const handlePreviousItem = (): void => {
    if (currentElement === 0) {
      setElement(loop ? 3 : currentElement);
    } else {
      setElement(currentElement - 1);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(handleLoopNextItem, 10000);
    return () => {
      clearInterval(intervalId);
    };
  }, [currentElement]);

  return (
    <div className="express__how-to-play__container">
      <div className="express__how-to-play__img-group">
        <div className="express__how-to-play__display-small">
          <div
            onClick={handlePreviousItem}
            className="express__how-to-play__chevron express__how-to-play__previous"
          >
            <img
              src={Icon14}
              className="express__how-to-play__image"
              alt="Seta anterior"
            />
          </div>
        </div>
        <img src={imagePhone[arrImages]} alt="Cartola Express" />
        <div className="express__how-to-play__display-small">
          <div
            onClick={handleNextItem}
            className="express__how-to-play__chevron express__how-to-play__next"
          >
            <img
              src={Icon15}
              className="express__how-to-play__image"
              alt="Seta proxima"
            />
          </div>
        </div>
      </div>
      <div className="express__how-to-play__text-group">
        {playList.map((item, index) => (
          <div
            key={item.title}
            className={`express__how-to-play__text-item express__how-to-play__text-item--${
              currentElement === index ? 'active' : 'deactivated'
            }`}
            onClick={() => media !== 'small' && setElement(index)}
          >
            <div className="express__how-to-play__display-small">
              <div className="express__how-to-play__stepper">
                {Array.from(Array(4)).map((_, indexStepper) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div
                    key={indexStepper}
                    className="express__how-to-play__stepper__group"
                  >
                    {currentElement !== indexStepper ? (
                      <div className="express__how-to-play__stepper__item" />
                    ) : (
                      <Timer
                        isActive={currentElement === index}
                        number={indexStepper + 1}
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="express__how-to-play__display-normal">
              <Timer isActive={currentElement === index} number={index + 1} />
            </div>
            <div className="express__how-to-play__text-item-wrapper">
              <h3 className="express__how-to-play__text-item__title">
                {item.title}
              </h3>
              <p className="express__how-to-play__text-item__text">
                {item.text}
              </p>
              {item?.subText && (
                <p className="express__how-to-play__text-item__text--tiny">
                  {item.subText}
                </p>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ExpressWebComponentHowToPlay;
