import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import { device } from './devices.style';

const GlobalStyle = createGlobalStyle`
${normalize}

@font-face{
    font-family:"proximanova-bold";
    src: url("https://s3.glbimg.com/cdn/fonts/proximanova/bold.woff2") format("woff2"),
        url("https://s3.glbimg.com/cdn/fonts/proximanova/bold.woff") format("woff"),
        url("https://s3.glbimg.com/cdn/fonts/proximanova/bold.ttf") format("truetype");
        
    font-style:normal;
    font-weight:normal
}

@font-face{
    font-family:"proximanova-regular";
    src:url("https://s3.glbimg.com/cdn/fonts/proximanova/regular.woff2") 
    format("woff2"),url("https://s3.glbimg.com/cdn/fonts/proximanova/regular.woff") 
    format("woff"),url("https://s3.glbimg.com/cdn/fonts/proximanova/regular.ttf") 
    format("truetype");
    font-style:normal;
    font-weight:normal}

@font-face{
  font-family:"opensans-regular";
  src:url("https://s3.glbimg.com/cdn/fonts/opensans/regular.woff2") 
  format("woff2"),url("https://s3.glbimg.com/cdn/fonts/opensans/regular.woff") 
  format("woff"),url("https://s3.glbimg.com/cdn/fonts/opensans/regular.ttf") 
  format("truetype");
  font-style:normal;
  font-weight:normal
}

@font-face{
  font-family:"opensans-light";
  src:url("https://s3.glbimg.com/cdn/fonts/opensans/light.woff2") 
  format("woff2"),url("https://s3.glbimg.com/cdn/fonts/opensans/light.woff") 
  format("woff"),url("https://s3.glbimg.com/cdn/fonts/opensans/light.ttf") 
  format("truetype");
  font-style:normal;
  font-weight:normal
}

@font-face{
  font-family:"opensans-bold";
  src:url("https://s3.glbimg.com/cdn/fonts/opensans/bold.woff2") 
  format("woff2"),url("https://s3.glbimg.com/cdn/fonts/opensans/bold.woff") 
  format("woff"),url("https://s3.glbimg.com/cdn/fonts/opensans/bold.ttf") 
  format("truetype");
  font-style:normal;
  font-weight:normal;
}

@font-face{
  font-family:"opensans-extrabold";
  src:url("https://s3.glbimg.com/cdn/fonts/opensans/extrabold.woff2") 
  format("woff2"),url("https://s3.glbimg.com/cdn/fonts/opensans/extrabold.woff") 
  format("woff"),url("https://s3.glbimg.com/cdn/fonts/opensans/extrabold.ttf") 
  format("truetype");
  font-style:normal;
  font-weight:normal
}

  body {
    font-family: ${(props) => props.theme.fonts.opensansRegular};
    margin: 0;
    background: ${(props) => props.theme.colors.greyLight};
  }

  ul {
    list-style-type: none;
  }

  button {
    background: none;
    border: none;
  }

  a {
    text-decoration: none;
  }
  
  p {
    font-size: 20px;
    line-height: 28px;
    margin: 0px 0px 16px 0px;

    @media ${device.mobileL} {
    font-size: 16px;
  }
  }

  b {
    font-family: ${(props) => props.theme.fonts.opensansBold};
    font-weight: 700;
  }
`;

export { GlobalStyle };
