import styled from 'styled-components';
import { device } from '../../../../pages/main/styles';

export const Container = styled.div`
  display: flex;
  flex-flow: column;
`;

export const SectionDisplay = styled.div`
  @media ${device.tabletL} {
    padding-left: 0px;
  }
`;
