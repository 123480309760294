import React from 'react';
import { Highlight } from './styles';

interface Props {
  text: string;
}

const HighlightIcon = ({ text = '' }: Props) => (
  <Highlight>
    <label>{text}</label>
  </Highlight>
);

export { HighlightIcon };
