export const scoreList = [
  {
    scout: '1º lugar',
    score: '40',
    event: 'Corrida Principal ',
  },
  {
    scout: '2º lugar',
    score: '33',
    event: 'Corrida Principal ',
  },
  {
    scout: '3º lugar',
    score: '30',
    event: 'Corrida Principal ',
  },
  {
    scout: '4º lugar',
    score: '27',
    event: 'Corrida Principal ',
  },
  {
    scout: '5º lugar',
    score: '25',
    event: 'Corrida Principal ',
  },
  {
    scout: '6º lugar',
    score: '23',
    event: 'Corrida Principal ',
  },
  {
    scout: '7º lugar',
    score: '21',
    event: 'Corrida Principal ',
  },
  {
    scout: '8º lugar',
    score: '19',
    event: 'Corrida Principal ',
  },
  {
    scout: '9º lugar',
    score: '17',
    event: 'Corrida Principal ',
  },
  {
    scout: '10º lugar',
    score: '15',
    event: 'Corrida Principal ',
  },
  {
    scout: '11º lugar',
    score: '10',
    event: 'Corrida Principal ',
  },
  {
    scout: '12º lugar',
    score: '9',
    event: 'Corrida Principal ',
  },
  {
    scout: '13º lugar',
    score: '8',
    event: 'Corrida Principal ',
  },
  {
    scout: '14º lugar',
    score: '7',
    event: 'Corrida Principal ',
  },
  {
    scout: '15º lugar',
    score: '6',
    event: 'Corrida Principal ',
  },
  {
    scout: '16º lugar',
    score: '5',
    event: 'Corrida Principal ',
  },
  {
    scout: '17º lugar',
    score: '4',
    event: 'Corrida Principal ',
  },
  {
    scout: '18º lugar',
    score: '3',
    event: 'Corrida Principal ',
  },
  {
    scout: '19º lugar',
    score: '2',
    event: 'Corrida Principal ',
  },
  {
    scout: '20º lugar',
    score: '1',
    event: 'Corrida Principal ',
  },
  {
    scout: '1º lugar',
    score: '20',
    event: 'Corrida Sprint/ Qualificação',
  },
  {
    scout: '2º lugar',
    score: '16.5',
    event: 'Corrida Sprint/ Qualificação',
  },
  {
    scout: '3º lugar',
    score: '15',
    event: 'Corrida Sprint/ Qualificação',
  },
  {
    scout: '4º lugar',
    score: '13.5',
    event: 'Corrida Sprint/ Qualificação',
  },
  {
    scout: '5º lugar',
    score: '12.5',
    event: 'Corrida Sprint/ Qualificação',
  },
  {
    scout: '6º lugar',
    score: '11.5',
    event: 'Corrida Sprint/ Qualificação',
  },
  {
    scout: '7º lugar',
    score: '10.5',
    event: 'Corrida Sprint/ Qualificação',
  },
  {
    scout: '8º lugar',
    score: '9.5',
    event: 'Corrida Sprint/ Qualificação',
  },
  {
    scout: '9º lugar',
    score: '8.5',
    event: 'Corrida Sprint/ Qualificação',
  },
  {
    scout: '10º lugar',
    score: '7.5',
    event: 'Corrida Sprint/ Qualificação',
  },
  {
    scout: '11º lugar',
    score: '5',
    event: 'Corrida Sprint/ Qualificação',
  },
  {
    scout: '12º lugar',
    score: '4.5',
    event: 'Corrida Sprint/ Qualificação',
  },
  {
    scout: '13º lugar',
    score: '4',
    event: 'Corrida Sprint/ Qualificação',
  },
  {
    scout: '14º lugar',
    score: '3.5',
    event: 'Corrida Sprint/ Qualificação',
  },
  {
    scout: '15º lugar',
    score: '3',
    event: 'Corrida Sprint/ Qualificação',
  },
  {
    scout: '16º lugar',
    score: '2.5',
    event: 'Corrida Sprint/ Qualificação',
  },
  {
    scout: '17º lugar',
    score: '2',
    event: 'Corrida Sprint/ Qualificação',
  },
  {
    scout: '18º lugar',
    score: '1.5',
    event: 'Corrida Sprint/ Qualificação',
  },
  {
    scout: '19º lugar',
    score: '1',
    event: 'Corrida Sprint/ Qualificação',
  },
  {
    scout: '20º lugar',
    score: '0.5',
    event: 'Corrida Sprint/ Qualificação',
  },
  {
    scout: 'Piloto do dia',
    score: '1',
    event: 'Corrida principal',
  },
  {
    scout: 'Piloto do dia',
    score: '0.5',
    event: 'Corrida Sprint',
  },
  {
    scout: 'Volta mais rápida',
    score: '2',
    event: 'Corrida Principal',
  },
  {
    scout: 'Volta mais rápida',
    score: '1',
    event: 'Corrida Sprint',
  },
  {
    scout: 'Vencedor e volta mais rápida  ',
    score: '1',
    event: 'Corrida Principal',
  },
  {
    scout: 'Vencedor e volta mais rápida  ',
    score: '0.5',
    event: 'Corrida Sprint',
  },
  {
    scout: 'Variação de posição (+/-): 0.2 por posição ',
    score: '0.2',
    event: 'Corrida Principal',
  },
  {
    scout: 'Variação de posição (+/-): 0.1 por posição ',
    score: '0.1',
    event: 'Corrida Sprint',
  },
  {
    scout: 'Chegou atrás do companheiro de equipe ',
    score: '-0.4',
    event: 'Corrida Principal',
  },
  {
    scout: 'Chegou atrás do companheiro de equipe ',
    score: '-0.2',
    event: 'Corrida Sprint/ Qualificação',
  },
  {
    scout: 'Chegou na frente do companheiro de equipe ',
    score: '0.4',
    event: 'Corrida Principal',
  },
  {
    scout: 'Chegou na frente do companheiro de equipe ',
    score: '0.2',
    event: 'Corrida Sprint/ Qualificação',
  },
  {
    scout: 'Único da equipe a completar',
    score: '2',
    event: 'Corrida Principal',
  },
  {
    scout: 'Desqualificado/Não terminou a corrida ',
    score: '-5.0',
    event: 'Corrida Principal',
  },
  {
    scout: 'Desqualificado/Não terminou a corrida ',
    score: '-2.5',
    event: 'Corrida Sprint/ Qualificação',
  },
  {
    scout: 'Único da equipe a completar ',
    score: '1.0',
    event: 'Corrida Sprint/ Qualificação',
  },
  {
    scout: 'Equipe: Concluiu a corrida (+2 pt. por carro) ',
    score: '4',
    event: 'Corrida Principal',
  },
  {
    scout: 'Equipe: Concluiu a corrida (+1 pt. por carro) ',
    score: '2',
    event: 'Corrida Sprint/ Qualificação',
  },
  {
    scout: 'Equipe: Pitstop mais rápido ',
    score: '2',
    event: 'Corrida Principal',
  },
  {
    scout: 'Equipe: 1º lugar ',
    score: '5',
    event: 'Corrida Principal',
  },
  {
    scout: 'Equipe: 2º lugar ',
    score: '4',
    event: 'Corrida Principal',
  },
  {
    scout: 'Equipe: 3º lugar ',
    score: '3',
    event: 'Corrida Principal',
  },
  {
    scout: 'Equipe: 4º lugar ',
    score: 2,
    event: 'Corrida Principal',
  },
  {
    scout: 'Equipe: 5º lugar ',
    score: '1',
    event: 'Corrida Principal',
  },
  {
    scout: 'Equipe: 1º lugar ',
    score: '2.5',
    event: 'Corrida Sprint/ Qualificação',
  },
  {
    scout: 'Equipe: 2º lugar ',
    score: '2',
    event: 'Corrida Sprint/ Qualificação',
  },
  {
    scout: 'Equipe: 3º lugar ',
    score: '1.5',
    event: 'Corrida Sprint/ Qualificação',
  },
  {
    scout: 'Equipe: 4º lugar ',
    score: 1,
    event: 'Corrida Sprint/ Qualificação',
  },
  {
    scout: 'Equipe: 5º lugar ',
    score: '0.5',
    event: 'Corrida Sprint/ Qualificação',
  },
];
