export const scoreListDef = [
  {
    scout: 'Sack',
    score: '1',
  },
  {
    scout: 'Interceptação',
    score: '2',
  },
  {
    scout: 'Fumble recuperado',
    score: '2',
  },
  {
    scout: 'Safety',
    score: '2',
  },
  {
    scout: 'Bloqueio de chute',
    score: '2',
  },
  {
    scout: 'Touchdown de retorno (Fumble/Interceptação)',
    score: '6',
  },
  {
    scout: 'Touchdown de retorno (Punt/Kickoff/FG)',
    score: '6',
  },
  {
    scout: '0 Pontos cedidos',
    score: '10',
  },
  {
    scout: '1-6 Pontos cedidos',
    score: '7',
  },
  {
    scout: '7-13 Pontos cedidos',
    score: '4',
  },
  {
    scout: '14-20 Pontos cedidos',
    score: '1',
  },
  {
    scout: '21-27 Pontos cedidos',
    score: '0',
  },
  {
    scout: '28-34 Pontos cedidos',
    score: '-1',
  },
  {
    scout: '35+ Pontos cedidos',
    score: '-4',
  },
];

export const scoreListAtk = [
  {
    scout: 'Jardas Aéreas',
    score: '0.04',
    positions: 'QB',
  },
  {
    scout: 'Jardas Terrestres',
    score: '0.1',
    positions: 'QB, RB, WR, TE',
  },
  {
    scout: 'Jardas de Recepção',
    score: '0.1',
    positions: 'RB, WR, TE',
  },
  {
    scout: 'Touchdown de passe',
    score: '4',
    positions: 'QB',
  },
  {
    scout: 'Touchdown terrestre',
    score: '6',
    positions: 'QB, RB, WR, TE',
  },
  {
    scout: 'Touchdown de Recepção',
    score: '6',
    positions: 'QB, RB, WR, TE',
  },
  {
    scout: 'Touchdown de retorno',
    score: '6',
    positions: 'QB, RB, WR, TE',
  },
  {
    scout: 'Interceptado',
    score: '-2',
    positions: 'QB',
  },
  {
    scout: 'Recepção',
    score: '1',
    positions: 'WR, TE',
  },
  {
    scout: 'Fumble sofrido',
    score: '-2',
    positions: 'QB, RB, WR, TE',
  },
  {
    scout: 'Conversão de Dois Pontos',
    score: '2',
    positions: 'QB, RB, WR, TE',
  },
];

export const abbrList = [
  {
    abbr: 'QB',
    description: 'Quarterback',
  },
  {
    abbr: 'RB',
    description: 'Running Back',
  },
  {
    abbr: 'WR',
    description: 'Wide Receiver',
  },
  {
    abbr: 'TE',
    description: 'Tight End',
  },
  {
    abbr: 'DEF/ST',
    description: 'Defense Special Team',
  },
];
