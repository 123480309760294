import * as React from 'react';

const { useCallback, useRef } = React;

export function useCustomRef<T>(
  cb: (node: HTMLElement) => void = (): void => undefined,
): [(node: HTMLElement | null) => void, React.RefObject<T | null>] {
  const ref = useRef<T | null>(null);
  const setRef = useCallback((node) => {
    if (node !== null) {
      ref.current = node;
      cb(node);
    }
  }, []);

  return [setRef, ref];
}
