import React, { ReactNode, useState } from 'react';
import './slider.style.scss';
import SliderItem, { SliderItemProps } from './slider-item';
import * as Icon from '../../assets/icons';
import { useSwipe } from '@/hooks';

interface Props {
  itens: SliderItemProps[];
}

const renderItens = (itens: SliderItemProps[], indiceSelecionado: number) => {
  let itemAnterior = itens[indiceSelecionado - 1];
  if (!itemAnterior) {
    itemAnterior = itens[itens.length - 1];
  }

  const itemAtual = itens[indiceSelecionado];

  let itemProximo = itens[indiceSelecionado + 1];
  if (!itemProximo) {
    itemProximo = itens[0];
  }

  const itensVisiveis = [itemAnterior, itemAtual, itemProximo];
  return (
    <div className="container-slider__slider-itens">
      {itensVisiveis.map((item, indice) => {
        return (
          <SliderItem
            key={`slider-item-${indice}`}
            icone={item.icone}
            titulo={item.titulo}
            texto={item.texto}
            ativo={item === itemAtual}
          />
        );
      })}
    </div>
  );
};

const renderControle = (
  numItens: number,
  indiceSelecionado: number,
  onClickBolinha: (indice: number) => void,
  onClickAnterior: () => void,
  onClickProximo: () => void,
) => {
  const bolinhas: ReactNode[] = [];

  for (let indice = 0; indice < numItens; indice++) {
    bolinhas.push(
      <Icon.IconBolinhaVazada
        key={`slider-item-${indice + 1}`}
        class={`container-slider__slider-controle__container-bolinhas__bolinha ${
          indiceSelecionado === indice &&
          'container-slider__slider-controle__container-bolinhas__bolinha--preenchida'
        }`}
        onClick={() => onClickBolinha(indice)}
      />,
    );
  }

  return (
    <div className="container-slider__slider-controle">
      <img
        className="container-slider__slider-controle__seta"
        src={Icon.IconAnterior}
        alt="Ícone de anterior"
        onClick={onClickAnterior}
      />
      <div className="container-slider__slider-controle__container-bolinhas">
        {bolinhas}
      </div>
      <img
        className="container-slider__slider-controle__seta"
        src={Icon.IconProximo}
        alt="Ícone de próximo"
        onClick={onClickProximo}
      />
    </div>
  );
};

const Slider = (props: Props): JSX.Element => {
  const [indiceSelecionado, setIndiceSelecionado] = useState(0);
  const swipeHandlers = useSwipe({
    onSwipedLeft: () => onClickAnterior(),
    onSwipedRight: () => onClickProximo(),
  });

  const onClickBolinha = (indice: number) => {
    setIndiceSelecionado(indice);
  };

  const onClickAnterior = () => {
    let indice = indiceSelecionado - 1;
    if (indice < 0) {
      indice = props.itens.length - 1;
    }

    setIndiceSelecionado(indice);
  };

  const onClickProximo = () => {
    let indice = indiceSelecionado + 1;
    if (indice >= props.itens.length) {
      indice = 0;
    }

    setIndiceSelecionado(indice);
  };

  return (
    <div className="container-slider" {...swipeHandlers}>
      {renderItens(props.itens, indiceSelecionado)}

      {renderControle(
        props.itens.length,
        indiceSelecionado,
        onClickBolinha,
        onClickAnterior,
        onClickProximo,
      )}
    </div>
  );
};

export default Slider;
