export const scoreList = [
  {
    action: 'Desarme (DS)',
    score: '+ 1,2 pontos ',
  },
  {
    action: 'Falta cometida (FC)',
    score: '- 0,3 ponto ',
  },
  {
    action: 'Gol contra (GC)',
    score: '- 3,0 pontos ',
  },
  {
    action: 'Cartão amarelo (CA)',
    score: '- 1,0 ponto ',
  },
  {
    action: 'Cartão vermelho (CV)',
    score: '- 3,0 pontos',
  },
  {
    action: 'Falta sofrida (FS)',
    score: '+ 0,5 ponto',
  },
  {
    action: 'Finalização na trave (FT)',
    score: '+ 3,0 pontos',
  },
  {
    action: 'Finalização defendida (FD)',
    score: '+ 1,2 pontos',
  },
  {
    action: 'Finalização para fora (FF)',
    score: '+ 0,8 ponto',
  },
  {
    action: 'Gol (G)',
    score: '+ 8,0 pontos',
  },
  {
    action: 'Impedimento (I)',
    score: '- 0,1 ponto',
  },
  {
    action: 'Pênalti perdido (PP)',
    score: '- 4,0 ponto',
  },
  {
    action: 'Pênalti cometido (PC)',
    score: '- 1,0 ponto',
  },
  {
    action: 'Pênalti sofrido (PS)',
    score: '+ 1,0 ponto',
  },
  {
    action: 'Assistência (A)',
    score: '+ 5,0 pontos',
    text: 'Será considerado “assistência” somente o último evento antes de uma finalização que resulte em gol, seja ele um passe, cruzamento, lançamento, desde que esse evento seja determinante para possibilitar a finalização. A assistência será considerada mesmo que em seu percurso a bola sofra um leve desvio em um atleta do time adversário, possibilitando a finalização. Desvios involuntários, erro de domínio ou perda da posse em lances que a bola sobra para finalização em gol do companheiro de equipe não serão computados como assistência.',
  },
];
