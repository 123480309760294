import React, { useState } from 'react';
import Chevron from '@/modules/entenda-mais/assets/icons/chevron-down.svg';
import * as S from './styles';

interface Props {
  header?: string;
  children?: JSX.Element;
  collapsed?: boolean;
}

const Accordion = ({ header, children, collapsed }: Props): JSX.Element => {
  const [collapse, setCollapse] = useState(false || collapsed);

  return (
    <S.AccordionContainer>
      <S.AccordionHeaderContainer onClick={(): void => setCollapse(!collapse)}>
        <S.AccordionHeader>{header}</S.AccordionHeader>
        <S.AccordionChevron src={Chevron} $rotate={collapse} />
      </S.AccordionHeaderContainer>
      {children && (
        <S.AccordionSection showSection={collapse}>
          {children}
        </S.AccordionSection>
      )}
    </S.AccordionContainer>
  );
};

export { Accordion };
