import React from 'react';
import { NavTab, NavItem, NavLink, NavIcon } from './styles';
import NewWindowIcon from '../../../assets/icons/new-window.svg';
import { HighlightIcon } from '../../../../entenda-mais/components/dataDisplay/highlight';
import { sendEvent } from '@/services/tracking';

interface ILinks {
  url?: string;
  label?: string;
  destacado?: boolean;
  isNew?: boolean;
  newTab?: boolean;
  action?: () => void;
  expressVisibility?: boolean;
  eventLabel?: string;
}

interface INavigation {
  links: ILinks[];
  selectedLink?: string;
  callBackAction: (event: React.MouseEvent, action?: string) => void;
}

const Navigation = ({
  links = [],
  selectedLink,
  callBackAction,
}: INavigation): JSX.Element => {
  const handleSendGAEvent = (eventLabel?: string): void => {
    eventLabel && sendEvent('express-entry-page', 'top-header', eventLabel);
  };

  return (
    <NavTab>
      {links.map((link) => {
        const classItemSelected = selectedLink?.endsWith(link.url || '')
          ? true
          : false;

        return (
          <NavItem
            key={`${link.url}-${link.label}`}
            selected={classItemSelected}
          >
            <NavLink
              href={link.url}
              rel={link.label}
              onClick={(event) => {
                handleSendGAEvent(link.eventLabel);
                link.action;
                callBackAction(event, link.eventLabel);
              }}
              target={link.newTab ? '_blank' : '_self'}
            >
              {link.label}
            </NavLink>
            {link.isNew && (
              <>
                <NavIcon>
                  <img src={NewWindowIcon} alt="Ícone de Navegação" />
                </NavIcon>
              </>
            )}
            {link.destacado && <HighlightIcon text="Novo" />}
          </NavItem>
        );
      })}
    </NavTab>
  );
};

export default Navigation;
