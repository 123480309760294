import React, { useState } from 'react';
import Minus from '@/modules/entenda-mais/assets/icons/minus.svg';
import Plus from '@/modules/entenda-mais/assets/icons/plus.svg';
import * as S from './styles';

interface Props {
  header?: string;
  children?: JSX.Element[] | JSX.Element;
}

const Collapse = ({ header, children }: Props): JSX.Element => {
  const [collapse, setCollapse] = useState(false);

  return (
    <div>
      <S.CollapseHeader
        onClick={(): void => setCollapse(!collapse)}
        isCollapse={collapse}
      >
        <S.CollapseHeaderText>{header}</S.CollapseHeaderText>
        <S.CollapseImage src={collapse ? Minus : Plus} />
      </S.CollapseHeader>
      <S.CollapseContent isCollapse={collapse}>{children}</S.CollapseContent>
    </div>
  );
};

export { Collapse };
