import React from 'react';
import { Accordion } from '@/modules/entenda-mais/components';
import * as S from './styles';
import { scoreList } from './scoreList';
import { Section } from '@/modules/entenda-mais/layout';

const Formula1 = (): JSX.Element => {
  const sectionDetail = {
    title: 'Automobilismo',
    text: [
      {
        paragraph: ``,
      },
    ],
    index: 5,
  };

  return (
    <Section
      header={sectionDetail.title}
      text={sectionDetail.text}
      index={sectionDetail.index}
    >
      <Accordion header="Montando seu time">
        <>
          <p>
            A primeira tela que aparecerá depois que você completar seu cadastro
            será a tela de criação do seu apelido. É o nome do seu time, e não
            poderá ser alterado – pense bem em qual nome você quer para o seu
            time.
            <br />
            Alguns pré-requisitos são necessários:
          </p>
          <S.List>
            <li>
              <S.ListText>
                Seu time deverá ser composto por 5 Pilotos e 1 Equipe
              </S.ListText>
            </li>
            <li>
              <S.ListText>
                Valor de cartoletas é variável a cada disputa.
              </S.ListText>
            </li>
            <li>
              <S.ListText>
                Se você escalar 2 pilotos de uma mesma equipe, esta equipe não
                poderá ser selecionada para o seu time
              </S.ListText>
            </li>
            <li>
              <S.ListText>
                Você deve definir um capitão, que terá a sua pontuação final
                multiplicada por 1,25. Apenas pilotos podem ser capitães
              </S.ListText>
            </li>
            <li>
              <S.ListText>
                O piloto com preço mais baixo será automaticamente definido como
                “Piloto Surpresa” e receberá um multiplicador de 1,25 na sua
                pontuação
              </S.ListText>
            </li>
            <li>
              <S.ListText>
                Se um piloto for substituído por qualquer motivo e não competir
                na Corrida sprint e/ou na Corrida Principal,{' '}
                <b>
                  os pontos acumulados pelo piloto substituto contarão para o
                  piloto principal
                </b>
                .
              </S.ListText>
            </li>
          </S.List>
        </>
      </Accordion>

      <Accordion header="Corridas interrompidas, adiadas e canceladas">
        <>
          <p>
            As Qualificações, corrida sprint e corrida principal computarão
            pontos para as disputas. Se algum dos eventos for interrompido por
            qualquer motivo e nenhum resultado oficial for publicado, as
            disputas serão canceladas e você receberá de volta todo o valor
            gasto em inscrições.
          </p>
        </>
      </Accordion>

      <Accordion header="Confirmação dos resultados">
        <>
          <p>
            Na Fórmula 1 há penalidades que podem ser impostas a pilotos e
            equipes e que afetam os pontos acumulados e os resultados da
            corrida. Em caso de penalidades, a posição final pode mudar. Existem
            penalidades financeiras ou advertências que não possuem impacto na
            corrida e por isso não impactam nas pontuações.
          </p>
          <p>
            Se houver mudanças de pontuação oriundas de penalidades até 30
            minutos após recebimento das pontuações finais do fornecedor de
            scouts, as novas pontuações serão consideradas e o Cartola Express
            poderá realizar correções na distribuição dos prêmios.
          </p>
          <p>
            Caso a possível penalidade seja reportada após o processamento de
            resultados, as pontuações não serão atualizadas e o resultado da
            Disputa não será alterado.
          </p>
        </>
      </Accordion>

      <Accordion header="Sistema de Pontuação">
        <>
          <p>
            Todas as disputas têm um número máximo de inscrições por usuário, a
            Cada piloto receberá pontos com base em seu desempenho durante o fim
            de semana de corrida. Quando a corrida terminar, os pontos serão
            verificados e confirmados. Os pilotos acumularão pontos em cada
            evento e o total de pontos será a soma dos pontos acumulados na
            Qualificação, Sprint e Corrida Principal.
          </p>
          <S.ScoreList>
            <S.ScoreListItem bgGrey={false}>
              <S.Row>
                <S.ScoreListHeader style={{ flex: '1' }}>
                  SCOUT
                </S.ScoreListHeader>
                <S.ScoreListHeader style={{ width: '40%' }}>
                  PONTOS
                </S.ScoreListHeader>
                <S.ScoreListHeader style={{ width: '20%' }}>
                  EVENTO
                </S.ScoreListHeader>
              </S.Row>
            </S.ScoreListItem>
            {scoreList.map((item, index: number) => (
              <S.ScoreListItem key={index} bgGrey={index % 2 === 0}>
                <S.Row>
                  <S.ScoreListAction style={{ flex: '1' }}>
                    {item.scout}
                  </S.ScoreListAction>
                  <S.ScoreListAction style={{ width: '40%' }}>
                    {item.score}
                    {Math.abs(Number(item.score)) > 1 ? ' pontos' : ' ponto'}
                  </S.ScoreListAction>
                  <S.ScoreListAction style={{ width: '20%' }}>
                    {item.event}
                  </S.ScoreListAction>
                </S.Row>
              </S.ScoreListItem>
            ))}
          </S.ScoreList>
        </>
      </Accordion>

      <Accordion header="Scouts de Piloto">
        <>
          <S.List>
            <li>
              <S.ListText>
                Volta Mais Rápida: concedido ao piloto que fizer a melhor volta
                individual durante a Corrida.
              </S.ListText>
            </li>
            <li>
              <S.ListText>
                Piloto do Dia: concedido ao piloto que recebeu mais votos do
                público durante a Corrida. Anunciado no final da transmissão de
                TV.
              </S.ListText>
            </li>
            <li>
              <S.ListText>
                Vencedor e volta mais rápida: quando o piloto conquista a
                vitória e a volta com menor tempo na mesma corrida.
              </S.ListText>
            </li>
            <li>
              <S.ListText>
                Variação de posição: diferença de posição entre a posição de
                início da corrida para o final da corrida.
              </S.ListText>
            </li>
            <li>
              <S.ListText>
                Chegou atrás do companheiro de equipe: diferença de posição para
                o piloto que terminou a corrida em uma posição final inferior ao
                companheiro de equipe. -0.2pts por posição, podendo pontuar no
                máximo -1pt.
              </S.ListText>
            </li>
            <li>
              <S.ListText>
                Chegou na frente do companheiro de equipe: diferença de posição
                para o piloto que terminou a corrida em uma posição final
                superior ao companheiro de equipe. +0.2pts (Qualificação e
                Sprint) / +0.4pts (Corrida Principal) por posição, podendo
                pontuar no máximo +1pt (Qualificação e Sprint) / +2pts (Corrida
                Principal).
              </S.ListText>
            </li>
            <li>
              <S.ListText>
                Desqualificado/não terminou a corrida: piloto que não tenha
                iniciado ou finalizado completamente a corrida qualquer motivo,
                de acordo com os resultados oficiais.
              </S.ListText>
            </li>
            <li>
              <S.ListText>
                Único da equipe a completar: pontuação concedida ao piloto da
                equipe que terminar a corrida na qual o companheiro não
                terminou.
              </S.ListText>
            </li>
          </S.List>
        </>
      </Accordion>

      <Accordion header="Scouts de Equipe">
        <>
          <S.List>
            <li>
              <S.ListText>
                Classificação de equipe: é baseada nos resultados dos seus
                pilotos. Para cada equipe é calculada a soma das posições dos
                dois carros. Em seguida, a equipe com o menor valor somado
                (diferença na posição geral) recebe o 1º lugar, a segunda recebe
                o 2º lugar e assim por diante. Ex: Caso os pilotos da equipe X
                terminem em 2º e 3º (soma = 5) e os da equipe Y terminem em 1º e
                5º (soma = 6). A equipe X ganharia o 1º lugar e a equipe Y o 2º
                lugar. Para os pilotos que não finalizam, consideramos suas
                posições como 20ª. Em caso de empate entre duas ou mais equipes,
                prevalecerá a equipe com o piloto que tiver a melhor
                classificação.
              </S.ListText>
            </li>
            <li>
              <S.ListText>
                Equipe: concluiu a corrida: é a quantidade de carros de cada
                equipe que terminaram a corrida. Este número pode ser:
                <S.List>
                  <li>
                    <S.ListText>Nenhum carro: 0 pt</S.ListText>
                  </li>
                  <li>
                    <S.ListText>Apenas um carro: 1pt</S.ListText>
                  </li>
                  <li>
                    <S.ListText>Ambos os carros: 2 pts</S.ListText>
                  </li>
                </S.List>
              </S.ListText>
            </li>
          </S.List>
        </>
      </Accordion>

      <Accordion header="Glossário">
        <>
          <S.List>
            <li>
              <S.ListText>
                Fim de semana de corrida: fim de semana de corrida completo
                (Qualificação + Corrida sprint + Corrida Principal).{' '}
              </S.ListText>
            </li>
            <li>
              <S.ListText>
                Qualificação: evento realizado no sábado ou sexta anterior a
                Corrida Principal. Vale tanto como Qualificação para Sprint como
                para Corrida Principal. Nesse evento o tempo usado para uma
                volta determina posição inicial dos pilotos nas corridas. Esse
                evento inclui todas as três etapas de qualificação, Q1, Q2 e Q3,
                e não inclui Treinos Livres.{' '}
              </S.ListText>
            </li>
            <li>
              <S.ListText>
                Corrida Sprint: corrida curta realizada no sábado em alguns
                finais de semana específicos de corrida.{' '}
              </S.ListText>
            </li>
            <li>
              <S.ListText>
                Qualificação: sessão realizada na sexta ou sábado anterior à
                Corrida Principal. O tempo usado para uma volta determina
                posição inicial dos pilotos na Corrida Principal. Esta corrida
                inclui todas as três etapas de qualificação, Q1, Q2 e Q3, e não
                inclui Treinos Livres.{' '}
              </S.ListText>
            </li>
            <li>
              <S.ListText>
                Corrida Principal: sessão final do fim de semana de corrida. É
                realizado no domingo e determina a classificação final.{' '}
              </S.ListText>
            </li>
            <li>
              <S.ListText>
                Posição de qualificação: a posição do piloto nos resultados
                oficiais de qualificação.{' '}
              </S.ListText>
            </li>
            <li>
              <S.ListText>
                Posição inicial: a posição do piloto antes do início da corrida.
                Isto pode diferir da posição de qualificação devido a
                penalidades impostas pelos comissários entre a qualificação e a
                corrida.{' '}
              </S.ListText>
            </li>
            <li>
              <S.ListText>
                Ranque final da corrida: a posição do piloto nos resultados
                oficiais da corrida.
              </S.ListText>
            </li>
          </S.List>
        </>
      </Accordion>
    </Section>
  );
};

export default Formula1;
