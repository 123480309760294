import styled from 'styled-components';
import { device } from '@/pages/main/styles';

interface StyledProps {
  isTablet?: boolean;
}

export const CarrouselWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;

  @media ${device.mobileL} {
    width: 100vw;
  }
`;

export const CarrouselContent = styled.div`
  display: flex;
  overflow: auto;
  position: relative;
  align-items: self-end;

  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-color: transparent transparent;

  ::-webkit-scrollbar {
    display: none;
  }

  .scrollhost ::-moz-scrollbar {
    display: none;
  }
`;

export const CarrouselCard = styled.div<StyledProps>`
  flex: none;
  pointer-events: none;

  :nth-child(1) {
    margin-left: 24px;
    opacity: 0;
    animation: fadeIn 1s 0.6s ease-out forwards;
  }

  :nth-child(3) {
    margin-right: 24px;
    opacity: 0;
    animation: fadeIn 1s 1s ease-out forwards;
  }

  :nth-child(2) {
    margin: 0 20px;
    opacity: 0;
    animation: fadeIn 1s 0.8s ease-out forwards;
    @media ${device.tabletMin} {
      margin: 0 15px;
    }
    div {
      div:nth-child(2) {
        padding: ${(props: StyledProps) =>
          props.isTablet ? '0 30px' : '0 38.5px'};
        @media ${device.tabletMin} {
          padding: 0px 24px 0;
        }
        h2 {
          b {
            font-size: 80px;
            @media ${device.tabletMin} {
              font-size: 64px;
            }
            @media ${device.mobileL} {
              font-size: 48px;
            }
          }
        }
      }
    }
  }
`;
