import useWindowSize from '@/hooks/useWindowSize';
import React from 'react';
import * as S from './styles';
import ArrowBack from '@/modules/entenda-mais/assets/icons/arrow-next.svg';

interface IHeader {
  text?: string;
}

const Header = ({ text }: IHeader) => {
  const { width } = useWindowSize();
  const isMobileSize = width <= 1100;

  const clickBackPage = () => {
    window.location.assign('/');
  };

  return (
    <S.HeaderContainer mobileSize={isMobileSize}>
      <S.HeaderButton onClick={(): void => clickBackPage()}>
        <img src={ArrowBack} alt="Voltar" />
      </S.HeaderButton>
      <S.HeaderText mobileSize={isMobileSize}>{text}</S.HeaderText>
    </S.HeaderContainer>
  );
};

export { Header };
