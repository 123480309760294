import styled from 'styled-components';
import { device } from '@/pages/main/styles';

interface StyledProps {
  isCollapse: boolean;
}

export const CollapseHeader = styled.div<StyledProps>`
  display: flex;
  padding: 8px;
  background-color: ${(props) => props.theme.colors.greyLightCollapse};
  margin-bottom: ${(props) => !props.isCollapse && '16px'};
  border-radius: 4px;
  cursor: pointer;
`;

export const CollapseHeaderText = styled.span`
  font-size: 22px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.grey};
  font-weight: 600;
  flex: 1;
  @media ${device.mobileL} {
    font-size: 16px;
  }
`;

export const CollapseContent = styled.div<StyledProps>`
  display: ${(props: StyledProps) => (props.isCollapse ? 'flex' : 'none')};
  flex-flow: column;
  padding: 16px 12px;
`;

export const CollapseImage = styled.img`
  @media ${device.mobileL} {
    width: 24px;
    height: 24px;
  }
`;
