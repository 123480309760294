import styled from 'styled-components';
import { device } from '@/pages/main/styles';

interface StyledProps {
  showSection?: boolean;
  $rotate?: boolean;
}

export const AccordionContainer = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 6px;
  margin-bottom: 8px;
  width: 100%;
`;

export const AccordionHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 24px 16px;
  background: ${(props) => props.theme.colors.white};
  border-radius: 6px;
  cursor: pointer;
`;

export const AccordionHeader = styled.h2`
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  flex: 1;
  margin: 0px;

  @media ${device.mobileL} {
    font-size: 16px;
  }
`;

export const AccordionSection = styled.div<StyledProps>`
  display: ${(props: StyledProps) => (props.showSection ? 'flex' : 'none')};
  flex-flow: column;
  padding: 0px 16px;
`;

export const AccordionChevron = styled.img<StyledProps>`
  width: 34px;
  height: 34px;
  transform: ${(props: StyledProps) =>
    props.$rotate ? 'rotate(-180deg)' : 'rotate(0)'};
  transition: transform 0.25s 0.15s;

  @media ${device.mobileL} {
    width: 24px;
    height: 24px;
  }
`;
