export const scoreListOtherCompetitions = [
  {
    action: 'Jogou até 60 minutos',
    position: 'Todas',
    score: '1',
  },
  {
    action: 'Jogou mais de 60 minutos',
    position: 'Todas',
    score: '2',
  },
  {
    action: 'Jogou a partida toda',
    position: 'Meias e atacantes',
    score: '3',
  },
  {
    action: 'Gol',
    position: 'Goleiro',
    score: '8',
  },
  {
    action: 'Gol',
    position: 'Defensores',
    score: '6',
  },
  {
    action: 'Gol',
    position: 'Meias',
    score: '5',
  },
  {
    action: 'Gol',
    position: 'Atacantes',
    score: '4',
  },
  {
    action: 'Jogo sem sofrer gol',
    position: 'Goleiro e defensores',
    score: '4',
  },
  {
    action: 'Jogo sem sofrer gol',
    position: 'Meias',
    score: '1',
  },
  {
    action: '2 gols sofridos',
    position: 'Goleiro e defensores',
    score: '-1',
  },
  {
    action: 'Assistência',
    position: 'Todas',
    score: '3',
  },
  {
    action: 'Gol contra',
    position: 'Todas',
    score: '-2',
  },
  {
    action: 'Pênalti perdido',
    position: 'Todas',
    score: '-2',
  },
  {
    action: 'Defesa',
    position: 'Goleiro',
    score: '0,5',
  },
  {
    action: 'Defesa de pênalti',
    position: 'Goleiro',
    score: '5',
  },
  {
    action: 'Cartão amarelo',
    position: 'Todas',
    score: '-1',
  },
  {
    action: 'Cartão vermelho',
    position: 'Todas',
    score: '-3',
  },
  {
    action: 'Falta cometida que provoca gol',
    position: 'Todas',
    score: '-2',
  },
  {
    action: 'Pênalti cometido',
    position: 'Todas',
    score: '-2',
  },
  {
    action: 'Vitória em campo',
    position: 'Todas',
    score: '0,3',
  },
  {
    action: 'Derrota em campo',
    position: 'Todas',
    score: '-0,3',
  },
  {
    action: 'Finalização defendida',
    position: 'Goleiro',
    score: '1',
  },
  {
    action: 'Finalização defendida',
    position: 'Defensores',
    score: '0,6',
  },
  {
    action: 'Finalização defendida',
    position: 'Meias e atacantes',
    score: '0,4',
  },
];
