import React from 'react';
import './slider-item.style.scss';

export interface SliderItemProps {
  icone: string;
  titulo: string;
  texto: string;
  ativo?: boolean;
}

const SliderItem = (props: SliderItemProps): JSX.Element => {
  return (
    <div className={`slider-item ${props.ativo && 'slider-item-ativo'}`}>
      <div
        className={`slider-item__titulo ${
          props.ativo && 'slider-item__titulo-ativo'
        }`}
      >
        <img
          className={`slider-item__icone ${
            props.ativo && 'slider-item__icone-ativo'
          }`}
          src={props.icone}
          alt={`Ícone do ${props.titulo}`}
        />
        {props.titulo}
      </div>

      <div
        className={`slider-item__texto ${
          props.ativo && 'slider-item__texto-ativo'
        }`}
      >
        <span dangerouslySetInnerHTML={{ __html: props.texto }} />
      </div>
    </div>
  );
};

export default SliderItem;
