export const scoreList5set = [
  {
    scout: 'Partida Vencida',
    score: '+2.5',
  },
  {
    scout: 'Ace',
    score: '+0.2',
  },
  {
    scout: 'Dupla Falta',
    score: '-0.3',
  },
  {
    scout: 'Game conquistado pelo recebedor',
    score: '+1.0',
  },
  {
    scout: 'Game perdido pelo sacador',
    score: '-0.4',
  },
  {
    scout: 'Bônus de mais de 15 Aces',
    score: '+1.5',
  },
  {
    scout: 'Bônus: Vitória sem perder Set',
    score: '0.67x',
  },
  {
    scout: 'Bônus por Perder Apenas 1 Set',
    score: '0.25x',
  },
  {
    scout: 'Set vencido por Tie Break',
    score: '+6.5',
  },
  {
    scout: 'Set vencido por 2 games de diferença',
    score: '+8.0',
  },
  {
    scout: 'Set vencido por 3 games de diferença',
    score: '+9.5',
  },
  {
    scout: 'Set vencido por 4 games de diferença',
    score: '+10.5',
  },
  {
    scout: 'Set vencido por 5 games de diferença',
    score: '+11',
  },
  {
    scout: 'Set vencido por 6 games de diferença',
    score: '+12.0',
  },
  {
    scout: 'Set perdido por Tie Break',
    score: '+5.5',
  },
  {
    scout: 'Set perdido por 2 games de diferença',
    score: '+4.0',
  },
  {
    scout: 'Set perdido por 3 games de diferença',
    score: '+2.5',
  },
  {
    scout: 'Set perdido por 4 games de diferença',
    score: '+1.5',
  },
  {
    scout: 'Set perdido por 5 games de diferença',
    score: '+1.0',
  },
  {
    scout: 'Set perdido por 6 games de diferença',
    score: '-1.0',
  },
];
