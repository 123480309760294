import styled from 'styled-components';
import { device } from '@/pages/main/styles';

export const DuvidasContainer = styled.section`
  background: #f7f7f7;
  padding-bottom: 64px;

  h1 {
    text-align: center;
    font-family: ${(props) => props.theme.fonts.opensansRegular};
  }

  hr {
    display: none;
  }

  p {
    margin: 0px 0px 16px 0px !important;
    font-size: 18px;
  }
`;

export const Image = styled.img`
  margin: 0px auto 20px auto;
`;

export const ImageFullLength = styled.img`
  margin: 30px auto;
  width: 30.5em;

  @media ${device.mobileL} {
    margin: 16px 0;
    width: auto;
  }
`;

export const Box = styled.div`
  background-color: rgba(233, 233, 233, 0.5);
  padding: 16px;
  margin: 0 0 16px;
  border-radius: 4px;
`;

export const BoxCenter = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 0 16px;
  background-color: rgba(233, 233, 233, 0.5);
`;

export const TinyText = styled.p`
  width: 220px;
  font-size: 14px;
  line-height: 20px;
  margin: 16px 12px;
  color: ${(props) => props.theme.colors.grey};
`;

export const ListText = styled.p`
  font-size: 20px;
  line-height: 28px;
  margin: 0 0px;
  padding-bottom: 10px;

  @media ${device.mobileL} {
    font-size: 16px;
  }
`;

export const List = styled.ul`
  list-style-type: disc;
  list-style-position: outside;
  margin: 0px 0 0 35px;
  p {
    margin-bottom: 0px !important;
  }
`;

export const Text = styled.p`
  padding-bottom: 10px;
  @media ${device.mobileL} {
    font-size: 16px;
  }
`;

export const GroupItem = styled.div`
  display: flex;
  margin: 6px 0px;
`;

export const RoundItem = styled.div`
  display: flex;
  border-radius: 50%;
  justify-content: center;
  width: 28px;
  height: 28px;
  background-color: ${(props) => props.theme.colors.orange};
  margin-right: 8px;
  text-align: center;
`;

export const RoundItemText = styled.span`
  align-self: center;
  font-size: 16px;
  line-height: 32px;
  padding: 9px;
  color: ${(props) => props.theme.colors.white};
`;

export const AlertImg = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 16px;
  margin-top: 10px;
`;

export const ArrowsIconImg = styled.img`
  width: 40px;
  height: 40px;
  margin-top: 15px;
`;
