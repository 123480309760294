export const scoreList = [
  {
    scout: 'Pontos',
    score: '1',
  },
  {
    scout: 'Rebote',
    score: '1.25',
  },
  {
    scout: 'Assistência',
    score: '1.5',
  },
  {
    scout: 'Roubada de Bola',
    score: '2',
  },
  {
    scout: 'Toco',
    score: '2',
  },
  {
    scout: 'Cesta de 3 pontos',
    score: '0.5',
  },
  {
    scout: 'Turnover',
    score: '-0.5',
  },
  {
    scout: 'Duplo-duplo',
    score: '1.5',
  },
  {
    scout: 'Triplo-duplo',
    score: '3',
  },
];
