import React from 'react';
import { HeaderContent, SubHeader } from './styles';

import Navigation from '../../navigation/navTab';
import ExpressLogoBranco from '@/modules/express/assets/img/logo_express_white.svg';
import { OrangeButtonFilled } from '../../button';
import { sendEvent } from '@/services/tracking';

interface IHeaderTop {
  children?: JSX.Element;
  header?: boolean;
  subHeader?: boolean;
  newColor?: boolean;
  callFanduel?: () => void;
  callBackRef?: (event: React.MouseEvent, refName: string) => void;
}

const HeaderTop = ({
  children,
  header = true,
  subHeader = false,
  newColor = false,
  callFanduel,
  callBackRef,
}: IHeaderTop): JSX.Element => {
  const defineLinkExpress = () => {
    return process.env.FANDUEL_ENDPOINT;
  };

  const linkList = [
    {
      url: defineLinkExpress(),
      label: 'Destaques',
      destacado: false,
      eventLabel: 'destaques',
    },
    {
      url: '#',
      label: 'Regras',
      eventLabel: 'regras',
    },
    {
      url: '#',
      label: 'Dúvidas frequentes',
      eventLabel: 'duvidas',
    },
    {
      url: 'https://ajuda.globo/cartola-express/faq/',
      label: 'Ajuda',
      newTab: true,
      eventLabel: 'ajuda',
    },
  ];

  const classicTabFixed = () => {
    const currentTab = `/${window.location.hash}`;

    return currentTab !== '/' ? 'https://cartolafc.globo.com/' : '/';
  };

  const handleCallBack = (event: React.MouseEvent, refName?: string) => {
    if (refName && callBackRef) {
      callBackRef(event, refName);
    }
  };

  const confirmaAcessoAoExpress = () => {
    sendEvent('express-entry-page', 'welcome-page-header', 'jogue-agora');
    if (callFanduel) {
      callFanduel();
    }
  };

  return (
    <>
      {header && (
        <HeaderContent bgColor={newColor}>
          <img
            className="express__content__logo"
            src={ExpressLogoBranco}
            alt="Cartola Express Logo"
          />
          <Navigation
            selectedLink={classicTabFixed()}
            callBackAction={(event, action) => handleCallBack(event, action)}
            links={linkList}
          />
          <div
            className="express__content__button"
            onClick={confirmaAcessoAoExpress}
          >
            <OrangeButtonFilled label="Jogue agora" />
          </div>
        </HeaderContent>
      )}
      {subHeader && <SubHeader bgColor={newColor}> {children} </SubHeader>}
    </>
  );
};

export { HeaderTop };
