import styled from 'styled-components';

interface StyledProps {
  itemActive: boolean;
}

export const Nav = styled.div`
  position: fixed;
  margin: 57px 0 0 24px;
`;

export const NavIndex = styled.div`
  display: flex;
  align-items: center;
`;

export const NavHeader = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.2px;
  margin: 3px 0px 0px 5px;
  color: ${(props) => props.theme.colors.orange};
`;

export const NavGroup = styled.ul`
  margin: 26px 0px;
  padding: 0px;
`;

export const NavGroupItem = styled.li<StyledProps>`
  display: flex;
  align-items: center;
  cursor: pointer;
  opacity: ${(props) => (props.itemActive ? '1' : '.25')};
  margin: 0px 10px 32px 10px;
`;

export const NavGroupItemText = styled.a`
  margin: 0px 10px;
  color: ${(props) => props.theme.colors.black};
  font-size: 14px;
  font-weight: 600;
  width: 250px;
`;

export const NavGroupItemSelected = styled.span<StyledProps>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.itemActive ? props.theme.colors.orange : 'none'};
`;
