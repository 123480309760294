import styled from 'styled-components';

export const Highlight = styled.div`
  display: flex;
  margin-left: 5px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 39px;
  height: 16px;
  background: #26ca5e;
  color: #fff;
  border-radius: 2px;
  font-family: ${(props) => props.theme.fonts.opensansBold};
  font-size: 10px;
  text-align: center;
  text-transform: uppercase;
`;
