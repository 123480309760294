import React from 'react';
import { Accordion } from '@/modules/entenda-mais/components';
import * as S from './styles';
import { Section } from '@/modules/entenda-mais/layout';

const PricePayment = (): JSX.Element => {
  const sectionDetails = {
    title: 'Processamento de resultados e Pagamento do Valor do Prêmio',
    // image: HowToPlayPicture03,
    text: [
      {
        paragraph: `A maioria dos resultados são efetivados logo após o término da
                    última Partida válida para a Disputa.`,
      },
    ],
    index: 2,
  };

  return (
    <Section
      header={sectionDetails.title}
      text={sectionDetails.text}
      // image={sectionDetails.image}
      index={sectionDetails.index}
    >
      <Accordion header="Processo de pagamento" collapsed>
        <>
          <S.Text>
            Para apurar o resultado das Disputas, será necessário:
          </S.Text>
          <S.GroupItem>
            <S.RoundItem>
              <S.RoundItemText>1</S.RoundItemText>
            </S.RoundItem>
            <S.Text>
              Recebimento de todas as súmulas das partidas que fazem parte das
              disputas;
            </S.Text>
          </S.GroupItem>
          <S.GroupItem>
            <S.RoundItem>
              <S.RoundItemText>2</S.RoundItemText>
            </S.RoundItem>
            <S.Text>
              Recebimento de todas as estatísticas dos atletas relatadas pelo
              fornecedor de scouts.
            </S.Text>
          </S.GroupItem>
          <S.Text>
            Ainda, em algumas situações pode haver{' '}
            <b>mudanças de última hora</b> se existir dificuldade na obtenção de
            resultados oficiais ou problemas com a pontuação por conta de algum
            dos casos acima.
          </S.Text>
          <S.Text>
            Com isso, o processamento de resultados poderá ser realizado no dia
            seguinte, bem como o pagamento do Valor dos Prêmios.
          </S.Text>
          <S.Text>Também podem ocorrer atrasos por conta de:</S.Text>
          <S.List>
            <li>
              <S.ListText>Problemas técnicos em nossos sistemas.</S.ListText>
            </li>
            <li>
              <S.ListText>
                Erros nos processamentos de resultados da rodada pelo Cartola
                Express.
              </S.ListText>
            </li>
          </S.List>
          <S.Text style={{ marginBottom: '16px' }}>
            Nestes casos, o processo de pagamento do Valor dos Prêmios pode ser
            revertido e as Disputas podem ganhar um <b>novo resultado</b>.
          </S.Text>
        </>
      </Accordion>
    </Section>
  );
};

export default PricePayment;
