import React from 'react';
import { Routes, BrowserRouter as Router, Route } from 'react-router-dom';

import Home from '@/modules/express/pages/homePage';
import Express from '@/modules/express';

const AppRouter = (): JSX.Element => {
  const handleOrigemId = (query: string) => {
    return query.includes('origemId')
      ? query
      : `${query}?origemId=${process.env.ORIGEM_ID}`;
  };

  const callFanduelLogin = React.useCallback(async () => {
    const query: string = window.location.search;
    let endpoint = process.env.GLOBO_ID_REDIRECT_URI;
    if (window.location.pathname == '/stg') {
      endpoint = process.env.STG_ENDPOINT;
    }

    window.location.assign(`${endpoint}${handleOrigemId(query)}`);
  }, []);

  return (
    <Router>
      <>
        <Routes>
          <Route
            path="/"
            element={<Express callFanduel={callFanduelLogin} />}
          />
          <Route
            path="/entenda-mais"
            element={<Home callFanduel={callFanduelLogin} />}
          />
          <Route
            path="/bem-vindo"
            element={<Express callFanduel={callFanduelLogin} />}
          />
          <Route
            path="/stg"
            element={<Express callFanduel={callFanduelLogin} />}
          />
        </Routes>
      </>
    </Router>
  );
};

export { AppRouter };
