import styled from 'styled-components';
import { device } from '@/pages/main/styles';

export const CardWrapper = styled.div`
  height: auto;
  border-radius: 8px;
`;

export const CardHeader = styled.div`
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #ff7400;
  color: ${(props) => props.theme.colors.white};
  text-align: center;
  text-transform: uppercase;
  padding: 12px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    padding-right: 8px;
  }
`;

export const CardBody = styled.div`
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 38.5px;
  border: 2px solid #e9e9e9;
  border-top: none;

  @media ${device.tabletMin} {
    padding: 0 30px;
  }
`;

export const CardBodyText = styled.h2`
  font-size: 28px;
  font-weight: 800;
  text-align: center;
  border-bottom: 1px solid #333333;
  padding-bottom: 6px;
  display: flex;
  align-items: center;

  b {
    font-size: 64px;
    margin-left: 4px;
    color: ${(props) => props.theme.colors.orange};
    letter-spacing: -1.6px;

    @media ${device.tabletMin} {
      font-size: 52px;
    }

    @media ${device.mobileL} {
      font-size: 48px;
    }
  }
`;

export const CardBodyFooter = styled.span`
  height: auto;
  width: 100%;
  color: #333333;
  text-align: center;
  padding: 6px 0 18px;
`;
