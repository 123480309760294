import styled from 'styled-components';
import { device } from '@/pages/main/styles';

interface StyledProps {
  showHeader?: boolean;
  bgColor?: boolean;
}

export const HeaderContent = styled.header<StyledProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 80px;
  color: ${(props) => props.theme.colors.white};
  font-family: ${(props) => props.theme.fonts.opensansRegular};
  padding: 16px 48px;
  position: fixed;
  width: 100%;
  z-index: 999;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: #0000b3;
  backdrop-filter: blur(9px);

  @media ${device.tablet} {
    padding: 0px 24px;
  }

  .express__content__button {
    margin: 0px;

    @media ${device.mobileL} {
      justify-content: flex-end;
      display: flex !important;
    }

    .cartola-button {
      @media ${device.mobileL} {
        width: 8.5rem;
        height: 2.5rem;
        padding: 0.875rem 1.5rem;
        font-size: 0.75rem;
      }
    }
  }

  .express__content__logo {
    @media ${device.mobileM} {
      width: 6.08288rem;
      height: 2.32113rem;
      flex-shrink: 0;
    }
  }
`;

export const SubHeader = styled.div<StyledProps>`
  height: 64px;
  background: ${(props) => (props.bgColor ? '#0000B3' : '#ff7400')};
  display: flex;
  justify-content: space-around;
  position: relative;

  img {
    max-height: 90%;
    margin-top: 10px;
  }

  button {
    position: absolute;
    right: 30px;
    width: 133px;
    margin-top: 10px;

    @media ${device.tablet} {
      width: 84px;
      right: 16px;
    }
  }
`;
