import React from 'react';

interface Props {
  class: string;
  onClick: () => void;
}

const IconBolinhaVazada = (props: Props): JSX.Element => {
  return (
    <svg
      width="9"
      height="9"
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.class}
      onClick={props.onClick}
    >
      <circle
        cx="4.72217"
        cy="4.28897"
        r="3.55556"
        stroke="#FF7400"
        strokeWidth="0.888889"
      />
    </svg>
  );
};

export default IconBolinhaVazada;
