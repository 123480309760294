import React from 'react';
import * as Icon from '../../assets/icons';
import './rules.style.scss';

const ExpressWebComponentRules = (): JSX.Element => {
  const rules = [
    {
      icon: Icon.Icon07,
      title: 'PATRIMÔNIO FIXO',
      text: 'Não há valorização de cartoletas para nenhuma disputa.',
    },
    {
      icon: Icon.Icon02,
      title: 'SUBSTITUIÇÕES',
      text: 'Seja com o banco de reservas ou a troca automática, substitua os atletas do seu time que não entrarem em campo.',
    },
    {
      icon: Icon.Icon06,
      title: 'Fechamento do Mercado',
      text: 'Escale até o último minuto e edite seu time até a rodada ou jogo único começar.',
    },
    {
      icon: Icon.Icon04,
      title: 'Mais esquemas táticos',
      text: 'Escale seu time com os atletas em cada posição, e a escalação se acomodará à formação desejada.',
    },
    {
      icon: Icon.Icon05,
      title: 'Multi Escalação',
      text: 'Aumente suas chances em disputas escalando vários times.',
    },
    {
      icon: Icon.Icon08,
      title: 'Autocompletar',
      text: 'Preencha as posições vazias do seu time rapidamente com o toque de um botão.',
    },
  ] as {
    icon: string;
    title: string;
    text: string;
    new?: string;
  }[];

  return (
    <div className="express__rules">
      {rules.map((rule) => (
        <div key={rule.title} className="express__rules__item-group">
          <img
            src={rule.icon}
            className="express__rules__image"
            alt={rule.title || 'regras'}
          />
          <div className="express__rules__text-group">
            <div className="express__rules__text-group__header">
              {rule.new && (
                <img
                  src={rule.new}
                  className="express__rules__new"
                  alt={rule.title || 'novo'}
                />
              )}
              <h4 className="express__rules__text-group__title">
                {rule.title}
              </h4>
            </div>

            <p className="express__rules__text-group__text">{rule.text}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ExpressWebComponentRules;
