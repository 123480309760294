import React, { lazy, Suspense, useEffect } from 'react';

import ExpressWebComponentHowToPlay from './components/how-to-play';
import ExpressWebComponentRules from './components/rules';

import './express.style.scss';
import { sendGAPageView } from '@/services/tracking';
import ExpressWebComponentDuvidas from './components/duvidas';
import Slider from './components/slider';
import * as Icon from './assets/icons';
import { SliderItemProps } from './components/slider/slider-item';

/* Import de components através do Lazy load */
const HeaderTop = lazy(() =>
  import('@/modules/express/components/header').then(({ HeaderTop }) => ({
    default: HeaderTop,
  })),
);

const ExpressWebComponentBanner = lazy(() =>
  import('./components/banner').then(({ ExpressWebComponentBanner }) => ({
    default: ExpressWebComponentBanner,
  })),
);

const ExpressWebComponentFooter = lazy(() =>
  import('./components/footer-questions').then(
    ({ ExpressWebComponentFooter }) => ({
      default: ExpressWebComponentFooter,
    }),
  ),
);

const Footer = lazy(() =>
  import('@/layout').then(({ Footer }) => ({
    default: Footer,
  })),
);

/* End - Import de components através do Lazy load */

interface Props {
  callFanduel: () => void;
}

declare global {
  interface Window {
    // add you custom properties and methods
    Cadun: any;
  }
}

const renderSlider = () => {
  const itens: SliderItemProps[] = [
    {
      icone: Icon.IconCifrao,
      titulo: 'Ganhe até R$500 em bônus!',
      texto:
        'O primeiro depósito vale mais! Garanta um bônus na carteira pra entrar em disputas e jogar muito mais.',
    },
    {
      icone: Icon.IconTrofeu,
      titulo: 'Mercado tá sempre aberto!',
      texto:
        'Rodada? Jogo único? Jogos por dia? A escolha é sua! Quer jogar todos? Também pode!',
    },
    {
      icone: Icon.IconBola,
      titulo: 'Tem muito campeonato!',
      texto:
        'Você pode escalar no Brasileirão, na Liberta, em Campeonatos Europeus e muito mais!',
    },
    {
      icone: Icon.IconCarteira,
      titulo: 'Para todos os bolsos',
      texto:
        'Entre nas disputas investindo a partir de <b>R$0,10!</b> Ah, e ainda tem opção grátis pra você começar a jogar.',
    },
    {
      icone: Icon.IconEstrela,
      titulo: 'E tem o Craque!',
      texto:
        'É só escalar o atleta que vai ser o destaque da rodada. Se ele for o maior pontuador, você corre pro abraço.',
    },
    {
      icone: Icon.IconCupom,
      titulo: 'Cupom? TEMOS!',
      texto:
        'Fica ligado nos nossos canais que você ainda tem a chance de encontrar cupons com bônus para escalar!',
    },
  ];

  return (
    <div className="container__slider">
      <Slider itens={itens} />
    </div>
  );
};

const ExpressWebComponent = ({ callFanduel }: Props): JSX.Element => {
  const getOrigemId = () => {
    const query: string = window.location.search;
    if (query.includes('origemId')) {
      const queryValueParsed = query.split('?')[1];
      return queryValueParsed.split('=')[1];
    }
  };

  const handleUserOrigem = () => {
    const origemId = getOrigemId();

    sendGAPageView('#!/express/landing-page-express', '', origemId);
  };

  useEffect(() => {
    handleUserOrigem();
  }, []);

  const regrasRef = React.useRef<HTMLInputElement>(null);
  const duvidasRef = React.useRef<HTMLInputElement>(null);

  const handleCallBackRef = (event: React.MouseEvent, refName: string) => {
    let offsetTop;
    if (refName === 'regras' && regrasRef.current) {
      offsetTop = regrasRef.current.offsetTop;
    } else if (refName === 'duvidas' && duvidasRef.current) {
      offsetTop = duvidasRef.current.offsetTop - 75;
    }

    if (offsetTop) {
      window.scrollTo({
        top: offsetTop,
        behavior: 'smooth',
      });
      event.preventDefault();
    }
  };

  return (
    <Suspense fallback="Carregando...">
      <div className="container">
        <HeaderTop callBackRef={handleCallBackRef} callFanduel={callFanduel} />

        <ExpressWebComponentBanner callFanduel={callFanduel} />

        {renderSlider()}

        <section className="express">
          <div className="express__content" ref={regrasRef}>
            <h2 className="express__content__header">
              Veja como é fácil jogar o Cartola Express:
            </h2>
            <ExpressWebComponentHowToPlay />
            <h2 className="express__content__header">
              Se liga, que a regra é clara e o desafio é grande
            </h2>
            <ExpressWebComponentRules />
          </div>
          <div ref={duvidasRef}>
            <ExpressWebComponentDuvidas />
          </div>
          <ExpressWebComponentFooter />
        </section>

        <Footer isChange={false} />
      </div>
    </Suspense>
  );
};

export default ExpressWebComponent;
