import styled from 'styled-components';
import { device } from '@/pages/main/styles';

export const Text = styled.p`
  font-size: 20px;
  line-height: 28px;
  margin: 0px;
  @media ${device.mobileL} {
    font-size: 16px;
  }
`;

export const GroupItem = styled.div`
  display: flex;
  margin: 12px 0px 16px 12px;
`;

export const RoundItem = styled.div`
  display: flex;
  border-radius: 50%;
  justify-content: center;
  width: 28px;
  height: 28px;
  background-color: ${(props) => props.theme.colors.orange};
  margin-right: 8px;
  text-align: center;
`;

export const RoundItemText = styled.span`
  align-self: center;
  font-size: 16px;
  line-height: 32px;
  padding: 9px;
  color: ${(props) => props.theme.colors.white};
`;

export const Image = styled.img`
  margin: 0px auto 24px;
`;

export const Box = styled.div`
  background-color: rgba(233, 233, 233, 0.5);
  padding: 16px;
  border-radius: 4px;
`;

export const ListText = styled.p`
  font-size: 20px;
  line-height: 28px;
  margin: 0px;
  @media ${device.mobileL} {
    font-size: 16px;
  }
`;

export const List = styled.ul`
  list-style-type: disc;
  margin: 0px 10px;
`;

export const Link = styled.a`
  color: ${(props) => props.theme.colors.orange};
  cursor: pointer;
`;
